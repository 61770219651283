import React, {useState,useEffect} from 'react'

import {Box} from '@chakra-ui/react'
import "@fontsource/jost"
 
const Informs=()=>{
    return(
        <Box>
            INFORMES
        </Box>
    )
}

export default Informs