//REACT
import React, { useEffect, useState } from 'react'
//AWS AUTH
import { Auth } from 'aws-amplify'
//REQUESTS
import axios from 'axios'
//FRONT
import { Flex, Box, FormControl, FormLabel, Input, Spinner,Stack,Button,ChakraProvider,extendTheme,Divider,Text,InputGroup,InputRightElement,IconButton} from '@chakra-ui/react'
//FUNCIÓN DE LA APP
import Content from './Content/index.tsx'
//ICONS
import {AiOutlineEye,AiOutlineEyeInvisible} from 'react-icons/ai'
 
//APP THEME
const theme = extendTheme({
  styles: {
  global: {body: {bg: "gray.100",fontFamily:'jost, sans-serif'}}},
  sizes: {iconButton: '10rem'},
  colors: {
  color:{
      500:'#1A365D'
}}})

//COMPONENTE LOGIN
const App: React.FC = () => {
  
  //Backend server
  const URL='https://api.fermat.es'

  //Obtaining access
  const [accessToken,setAccessToken]=useState(null)

  //Controlar la visibilidad de Login o Sign up
  const [showLogin,setShowLogin]=useState(true)

  //Checkear si el ususario está logeado en el navegador
  const [isSignedIn, setIsSignedIn] = useState(null)

  //Constantes del Sign up
  const [username, setUsername] = useState('')
  const [mail,setMail]=useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword,setRepeatPassword]=useState('')
  const [authToken,setAuthToken]=useState('')
  const [companyName,setCompanyName]=useState('')
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)
  const [showCreate,setShowCreate]=useState(false)
  
  //Errores relacionados con la contraseña
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [passwordError, setPasswordError] = useState('')

  //Controlar la visibilidad de la espera a la confirmación
  const [authWait,setAuthWait]=useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  let checkInterval: NodeJS.Timeout | null = null

  const checkEmailConfirmation = async () => {
    try {
      console.log({username:mail, password:password})
      const userLogin = await Auth.signIn({username:mail, password:password})
      localStorage.setItem('mail',mail)
      setUsername('')
      setPassword('')
      setIsSignedIn(true)
      setAuthWait(false)
      clearInterval(checkInterval)
    } catch (error) {
      console.error('Error al iniciar sesión', error);
    }
  }

  useEffect(() => {
    if (authWait && !isConfirmed) {
      const checkInterval = setInterval(checkEmailConfirmation, 5000)
      return () => {
        clearInterval(checkInterval)
      }
    }
  }, [authWait, isConfirmed])

  useEffect(() => {
    async function checkAccessToken() {
      try {
        const currentSession = await Auth.currentSession()
        const newAccessToken = currentSession.getIdToken().getJwtToken()
        setAccessToken(newAccessToken)
      } catch (error) {
        console.error('Error al obtener la sesión actual', error);
      }}
    checkAccessToken()}, [isSignedIn])

  useEffect(() => {
    if (authWait) {
      const timer = setTimeout(() => {
        setAuthWait(false)
        setShowLogin(true)
      }, 180000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [authWait])

  const isPasswordValid = (password) => {
    // Verifica si la contraseña cumple con los requisitos
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    return password.length >= minLength && hasUpperCase;
  }
 
  //Constantes del login
  const [usernameLogin,setUserNameLogin]=useState('')
  const [passwordLogin,setPasswordLogin]=useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showPasswordLogin, setShowPasswordLogin] = useState(false)
  const [passwordErrorLogin, setPasswordErrorLogin] = useState('')

  //Función para logearse
  const handleSignIn = async () => {
    try {
        setIsLoadingLogin(true);
        const userLogin = await Auth.signIn(usernameLogin, passwordLogin)
        setIsSignedIn(true)
        console.log("Successful sign in!")
        localStorage.setItem('mail',usernameLogin)
        setUserNameLogin('')
        setPasswordLogin('')
   
      } 
      catch (error) {
      setPasswordErrorLogin('E-mail o contraseña incorrectos')
      console.log('Error signing in:', error)
    }
    finally {
      setIsLoadingLogin(false)
    }
  }

  const handleSignUp=async ()=>{
    setIsLoading(true)
    const containsInvalidCharacters = /[^a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);
    if (!isPasswordValid(password) || containsInvalidCharacters) {
      setPasswordError('La contraseña debe tener al menos 8 caracteres, una mayúscula y caracteres especiales válidos.');
    }  
    else if (password !== repeatPassword) {
      setPasswordError('Las contraseñas no coinciden.')
    }  
    else if (showCreate &&companyName==''){
      setPasswordError('Introduce el nombre de la organización')
    }
    else if (!showCreate &&authToken==''){
      setPasswordError('Introduce código de invitación')
    }
    else{
      setPasswordError('')
      if (showCreate){
        try {
            console.log({username:mail,organization_key:companyName})
            const response = await axios.post(URL+'/new-organization',{username:mail,organization:companyName})
            try {
              const user = await Auth.signUp({username:mail,password:password,attributes:{name:username}})
              setUsername('')
              setRepeatPassword('')
              setCompanyName('')
              setAuthToken('')
              console.log("Successful sign up!")
              setAuthWait(true)
            } catch (error) {
              console.log('Error signing up:', error)
            }
          }   
          catch (error) { 
            setPasswordError(error)
          }
          finally {
            setIsLoading(false)
          }
        }
        else{
          try {
            console.log({username: mail,organization_key:authToken})
            const response = await axios.post(URL+'/join-organization',{username:mail,organization_key:authToken})
            try {
              const user = await Auth.signUp({username:mail,password:password,attributes:{name:username}})
              setUsername('')
              setRepeatPassword('')
              setCompanyName('')
              setAuthToken('')
              console.log("Successful sign up!")
              setAuthWait(true)
            } catch (error) {
              console.log('Error signing up:', error)
            }
          }   
          catch (error) { 
 console.log(error)
          }
          finally {
            setIsLoading(false)
          }
        }
      }}
  
  //Función para desloguearse
  const handleSignOut = async () => {
    try {
      await Auth.signOut()
      setIsSignedIn(false)
      localStorage.removeItem('isSignedIn')
    } catch (error) {
      console.log('Error signing out:', error);
    }}

  //Activar el login al apretar ENTER
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (showLogin){
      handleSignIn()}
      else{
        handleSignUp()
      }
    }}

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        setIsSignedIn(true)
        console.log('User is authenticated')
      } catch (error) {
        console.log('User is not authenticated')
        setIsSignedIn(false)
      }
    }
    checkAuthentication()}, [])

    if (isSignedIn==null||(isSignedIn&&!accessToken)){
      return(
        <></>
      )
    }
    //FRONT
    if (isSignedIn==false) {
      return (
        <ChakraProvider theme={theme}>
          {authWait?<>
          <Flex height={'100vh'}  bg='gray.100'  justifyContent={'center'} alignItems={'center'}> 
          <Flex p={8} borderRadius={'xl'} bg='white' width={'25vw'} shadow={'xl'} flexDirection={'column'} alignItems={'center'}>       
            <Text textAlign={'center'}>Hemos enviado un mail a {mail} para verificar la validez de su cuenta. Siga las instrucciones del mail para confirma su cuenta.</Text>
          </Flex>
          </Flex>
        </>:
        <> 
          {showLogin?   
          <Flex height={'100vh'}  bg='gray.100'  justifyContent={'center'} alignItems={'center'}> 
            <Box width='22vw'rounded={'lg'} bg='white' boxShadow={'lg'} p={8} >
              <Stack spacing={'2vh'} color='color.500'>
                <FormControl id="username">
                <FormLabel color='black' fontStyle={'jost'}>Usuario</FormLabel>
                <Input  fontStyle={'jost'} focusBorderColor='color.500' type="text" value={usernameLogin} onChange={(e) => setUserNameLogin(e.target.value)}  placeholder='Usuario' onKeyDown={handleKeyDown}/>
                </FormControl>
                <FormControl id="password">
                <FormLabel color='black'  fontStyle={'jost'} >Contraseña</FormLabel>
                  <InputGroup mt='2vh'>
                    <Input onKeyDown={handleKeyDown} fontStyle={'jost'}focusBorderColor='color.500'type={showPasswordLogin ? 'text' : 'password'}value={passwordLogin}onChange={(e)=>setPasswordLogin(e.target.value)}placeholder='Contraseña'/>
                    <InputRightElement>
                      <IconButton aria-label='showPasswordLogin' variant={'ghost'} colorScheme='white' icon={showPasswordLogin? <AiOutlineEye/>:<AiOutlineEyeInvisible/> } size="sm" onClick={() => setShowPasswordLogin(!showPasswordLogin)}/>
                    </InputRightElement>
                  </InputGroup> 
                  </FormControl>
                  {passwordErrorLogin && (
              <Text color="red" mt={1}>
                {passwordErrorLogin}
              </Text>
                )}
                  <Text >¿No tienes cuenta? <span style={{cursor:'pointer',textDecoration:'underline',fontWeight:'bold'}} onClick={()=>setShowLogin(false)}>Crear cuenta</span> </Text>
                  <Button fontWeight={'normal'} fontStyle={'jost'}  colorScheme='color'onClick={handleSignIn}>
                  {isLoadingLogin ? <Spinner size="sm" /> : 'Acceder'}
                </Button>
              </Stack>
            </Box>
          </Flex>
          :
          <Flex height={'100vh'}  bg='gray.100'  justifyContent={'center'} alignItems={'center'}>
            <Box width='22vw'rounded={'lg'} bg='white' boxShadow={'lg'} p={8} >
              <Stack spacing={'2vh'} color='color.500'>
                <Text fontWeight={'bold'} fontSize={'xl'}>Usuario</Text>
                <FormControl id="username">
                <FormLabel color='black' fontStyle={'jost'}>Nombre completo</FormLabel>
                <Input  fontStyle={'jost'} focusBorderColor='color.500' type="text" value={username} onChange={(e) => setUsername(e.target.value)}  placeholder='Nombre completo' onKeyDown={handleKeyDown}/>
                </FormControl>
                <FormControl id="mail">
                  <FormLabel color='black' fontStyle={'jost'}>E-mail</FormLabel>
                  <Input  fontStyle={'jost'} focusBorderColor='color.500' type="text" value={mail} onChange={(e) => setMail(e.target.value)} placeholder='E-mail' onKeyDown={handleKeyDown}/>
                </FormControl>
                <FormControl id="password">
                  <FormLabel color='black'  fontStyle={'jost'} >Contraseña</FormLabel>
                  <InputGroup>
                    <Input fontStyle={'jost'}focusBorderColor='color.500'type={showPassword ? 'text' : 'password'}value={password}onChange={(e)=>setPassword(e.target.value)}placeholder='Contraseña'/>
                    <InputRightElement>
                      <IconButton aria-label='showPassword' variant={'ghost'} colorScheme='white' icon={showPassword ?  <AiOutlineEye/>:<AiOutlineEyeInvisible/> } size="sm" onClick={() => setShowPassword(!showPassword)}/>
                    </InputRightElement>
                  </InputGroup> 
                <InputGroup mt='2vh'>
                    <Input fontStyle={'jost'}focusBorderColor='color.500'type={showPassword2 ? 'text' : 'password'}value={repeatPassword}onChange={(e)=>setRepeatPassword(e.target.value)}placeholder='Repetir contraseña'/>
                    <InputRightElement>
                      <IconButton aria-label='showPassword2' variant={'ghost'} colorScheme='white' icon={showPassword2 ? <AiOutlineEye/>:<AiOutlineEyeInvisible/> } size="sm" onClick={() => setShowPassword2(!showPassword2)}/>
                    </InputRightElement>
                  </InputGroup>  </FormControl>
                <Divider/>
                <Flex gap={'1vw'} justifyContent={'space-between'} alignItems={'center'}> 
                  <Text fontWeight={'bold'} fontSize={'xl'}>Organización</Text>
                  <Button size='xs' fontWeight={'normal'} bg={showCreate?'color.500':'gray.100'} _hover={{bg:'color.500', color:'white'}}color={showCreate?'white':'black'}  onClick={()=>setShowCreate(true)} >Crear</Button>
                  <Button  size='xs' fontWeight={'normal'}  bg={!showCreate?'color.500':'gray.100'} _hover={{bg:'color.500', color:'white'}}color={!showCreate?'white':'black'} onClick={()=>setShowCreate(false)} >Unirme</Button>
                </Flex>
                {showCreate ? (
                  <FormControl id="org_name">
                    <FormLabel color='black' fontStyle={'jost'}>Nombre</FormLabel>
                    <Input  fontStyle={'jost'} focusBorderColor='color.500' type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder='Nombre'  onKeyDown={handleKeyDown}/>
                  </FormControl>
                ) : (
                  <FormControl id="code">
                    <FormLabel color='black' fontStyle={'jost'}>Código de invitación</FormLabel>
                    <Input  fontStyle={'jost'} focusBorderColor='color.500' type="text" value={authToken} onChange={(e) => setAuthToken(e.target.value)} placeholder='Código de invitación'  onKeyDown={handleKeyDown}/>
                  </FormControl>
                )}
                <Stack>
                  <Button fontWeight={'normal'} fontStyle={'jost'}  colorScheme='color'onClick={handleSignUp} isDisabled={mail==''||username==''}>
                    {isLoading ? <Spinner size="sm" /> : 'Registrarse'}
                  </Button>
                  {passwordError && (
              <Text color="red" mt={1}>
                {passwordError}
              </Text>
                )}
                  <Text>¿Ya tienes una cuenta? <span style={{cursor:'pointer',textDecoration:'underline',fontWeight:'bold'}} onClick={()=>setShowLogin(true)}>Inicie sesión</span> </Text>
                </Stack>
              </Stack>
            </Box>
        </Flex>}
        </>
        }
        </ChakraProvider>
    )
  }
  return (
    <ChakraProvider theme={theme}> 
        <Content handleSignOut={handleSignOut} URL={URL} accessToken={accessToken} setAccessToken={setAccessToken}/>
    </ChakraProvider>
  ) 
}

export default App


 