//REACT
import React, {useState,useEffect} from 'react'
//AUTH
import { Auth, Storage } from 'aws-amplify'
//REQUESTS
import axios from 'axios'
//FRONT
import {Flex,Box,extendTheme,ChakraProvider,Button,IconButton,Text,Divider,Input,List,ListItem,Icon,Checkbox, Center,Spinner} from '@chakra-ui/react'
import "@fontsource/jost"
//SECCIONES
import Documents from './documents.tsx'
import Questions from './questions.tsx'
import Informs from './informs.tsx'

//ANIMACIONES
import {motion,AnimatePresence} from 'framer-motion'
//ICONOS
import {AiOutlinePlus,AiOutlineUp} from 'react-icons/ai'
import {BiUpload,BiLogOut} from 'react-icons/bi'
import {BsPeopleFill,BsPersonFillAdd,BsFillTrash3Fill} from 'react-icons/bs'
import {GiHamburgerMenu} from 'react-icons/gi'
import {IoMdSettings} from 'react-icons/io'
import {GrDocument,GrAdd} from 'react-icons/gr'
import {HiMiniMagnifyingGlass,HiOutlineClipboard} from 'react-icons/hi2'
import {RxCross2} from 'react-icons/rx'
 
Storage.configure({
  AWSS3: {
    region: "eu-west-3",
    bucket: "fermat-documentos"
  }
})

//APP THEME
const theme = extendTheme({
    styles: {
    global: {body: {bg: "gray.100",fontFamily:'jost, sans-serif'}}},
    sizes: {iconButton: '10rem'},
    colors: {color:{500:'#1A365D'}}})
 
//Function for style the collaborators list
const Collaborators=({name,colaboratorList,setColaboratorList,showCaseSettings,setAddedUsers,addedUsers})=>{
  const handleCheckBox=(user)=>{
 
  if(colaboratorList.includes(name)){setColaboratorList(colaboratorList.filter(item => item !== user))}
  else{setColaboratorList([...colaboratorList, name])}
}
    return(
    <Flex gap={5}>
        <Checkbox size='md' colorScheme='color' onChange={()=>{handleCheckBox(name)}}/>
        <Text>{name}</Text>
    </Flex>)
}

//Copiar variable a portpapeles
const copyToClipboard = (text) => {
  const elementoTemporal = document.createElement('textarea')
  elementoTemporal.value = text;
  document.body.appendChild(elementoTemporal)
  elementoTemporal.select()
  document.execCommand('copy')
  document.body.removeChild(elementoTemporal)
  alert('¡Texto copiado al portapapeles!')
}

//MAIN FUNCTION
const Content=({handleSignOut,URL,accessToken,setAccessToken})=>{

  const Mail=localStorage.getItem('mail')
  const getSection=localStorage.getItem('section')

  //Boleano para ver si estoy en un contrato o no
  const [isContract,setIsContract]=useState(false)
  //Booleano para la carga de caso
  const [waitingNewCase,setWaitingNewCase]=useState(false)
  //Organización
  const [Organization,setOrganization]=useState('')
  //Access type
  const [Access_type,setAccessType]=useState('')
  //Sección
  const [section,setSection]=useState(getSection?getSection:'Documentos')
  //Lista de casos
  const [CaseList,setCaseList]=useState([])
  //Lista de contratos
  const [ContractsList,setContractsList]=useState(['Contrato 1','Contrato 2','Contrato 3','Contrato 4'])
  //Nombre del caso actual
  const [caseNameTrue,setCaseNameTrue]=useState('')
  //Nombre del contrato actual
  const [contractNameTrue,setContractNameTrue]=useState('')
  //Lista de colaboradores
  const [colaboratorList,setColaboratorsList]=useState([])
  //List de ususarios
  const [employeesList,setEmployeesList]=useState([])
  //Lista de ususarios activos
  const [usersList,setUsersList]=useState([])
 
  //Booleano mostrar ajustes del caso
  const [showCaseSettings,setShowCaseSettings]=useState(false)
  const [caseUsers,setCaseUsers]=useState([])
  const [caseUsersType,setCaseUsersType]=useState([])
  const [showAddUsers,setShowAddUsers]=useState(false)
  const [addedUsers,setAddedUsers]=useState([])

  const [userAdded,setUserAdded]=useState('')
  const [userAddedType,setUserAddedType]=useState('')
  const [userDeleted,setUserDeleted]=useState('')
  const [caseDeleted,setCaseDeleted]=useState('')


  const handleAddUser=async ()=>{  

    try {
      if (!accessToken) {
        console.error('Token de acceso no disponible')
        return
      } 
      const response = await axios.post(URL+'/add-user-to-case',{organization:Organization,case:caseNameTrue,added_user:userAdded,access_type:userAddedType},{headers: {'Authorization': `Bearer ${accessToken}`}})
      const responseCase = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseNameTrue},{headers: {'Authorization': `Bearer ${accessToken}`}})
      setCaseUsers(responseCase.data.users)
      setCaseUsersType(responseCase.data.user_types)

    }
      catch (error) {
        console.log('Error sending the code:', error)
        if (error.response && error.response.status === 401) {
            try {
              await Auth.currentAuthenticatedUser();
              const currentSession = await Auth.currentSession();
              const newAccessToken = currentSession.getIdToken().getJwtToken()
              setAccessToken(newAccessToken)
              
              const response = await axios.post(URL+'/add-user-to-case',{organization:Organization,case:caseNameTrue,added_user:userAdded,access_type:userAddedType},{headers: {'Authorization': `Bearer ${accessToken}`}})
              const responseCase = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseNameTrue},{headers: {'Authorization': `Bearer ${accessToken}`}})
              setCaseUsers(responseCase.data.users)
              setCaseUsersType(responseCase.data.user_types)

            } catch (refreshError) {
              console.error('Error al refrescar el token', refreshError)  
              }
          }}
  }
  const handleDeleteUser=async ()=>{

 
    try {
      if (!accessToken) {
        console.error('Token de acceso no disponible')
        return
      } 
      console.log({organization:Organization,case:caseNameTrue,removed_user:userDeleted})
      const response = await axios.post(URL+'/remove-user-from-case',{organization:Organization,case:caseNameTrue,removed_user:userDeleted},{headers: {'Authorization': `Bearer ${accessToken}`}})
      const responseCase = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseNameTrue},{headers: {'Authorization': `Bearer ${accessToken}`}})
      setCaseUsers(responseCase.data.users)
      setCaseUsersType(responseCase.data.user_types)
      setUserDeleted('')

    }
      catch (error) {
        console.log('Error sending the code:', error)
        if (error.response && error.response.status === 401) {
            try {
              await Auth.currentAuthenticatedUser();
              const currentSession = await Auth.currentSession();
              const newAccessToken = currentSession.getIdToken().getJwtToken()
              setAccessToken(newAccessToken)
              
              const response = await axios.post(URL+'/remove-user-from-case',{organization:Organization,case:caseNameTrue,removed_user:userDeleted},{headers: {'Authorization': `Bearer ${accessToken}`}})
              const responseCase = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseNameTrue},{headers: {'Authorization': `Bearer ${accessToken}`}})
              setCaseUsers(responseCase.data.users)
              setCaseUsersType(responseCase.data.user_types)
              setUserDeleted('')
 
            } catch (refreshError) {
              console.error('Error al refrescar el token', refreshError)  
              }
          }}
  }
  const handleDeleteCase=async ()=>{
    try {
      if (!accessToken) {
        console.error('Token de acceso no disponible')
        return
      } 
      console.log({organization:Organization,case:caseDeleted})
      const response = await axios.post(URL+'/remove-case',{organization:Organization,case:caseNameTrue},{headers: {'Authorization': `Bearer ${accessToken}`}})
      setCaseList(CaseList.filter(item => item !== caseNameTrue))
      setFilteredCaseList(CaseList.filter(item => item !== caseNameTrue))
      if (CaseList.length-1!=0){
        setCaseNameTrue((CaseList.filter(elemento => elemento !== caseDeleted))[0])
        handleSetCase((CaseList.filter(elemento => elemento !== caseDeleted))[0],Organization)}
        else{setCaseNameTrue('')}
    }
      catch (error) {
        console.log('Error sending the code:', error)
        if (error.response && error.response.status === 401) {
            try {
              await Auth.currentAuthenticatedUser();
              const currentSession = await Auth.currentSession();
              const newAccessToken = currentSession.getIdToken().getJwtToken()
              setAccessToken(newAccessToken)
              const response = await axios.post(URL+'/remove-case',{organization:Organization,case:caseNameTrue},{headers: {'Authorization': `Bearer ${accessToken}`}})
              setCaseList(CaseList.filter(item => item !== caseNameTrue))
              setFilteredCaseList(CaseList.filter(item => item !== caseNameTrue))
              if (CaseList.length-1!=0){
                setCaseNameTrue((CaseList.filter(elemento => elemento !== caseDeleted))[0])
                handleSetCase((CaseList.filter(elemento => elemento !== caseDeleted))[0],Organization)}
                else{setCaseNameTrue('')}
            } catch (refreshError) {
              console.error('Error al refrescar el token', refreshError)  
              }
          }}
  }
  //INFORMACION DEL CASO
  const [documentsList,setDocumentsList]=useState([])
  const [documentsDates,setDocumentsDates]=useState([])
  const [documentsSummaries,setDocumentsSummaries]=useState([])
  const [documentsUrls,setDocumentsUrls]=useState([])
  const [documentsTypes,setDocumentsType]=useState([])
  const [consultList,setConsultList]=useState([])
  const [consultDates,setConsultDates]=useState([])
  const [consultIds,setConsultIds]=useState([])
  
  //Informacion de un contrato
  const [client,setClient]=useState('Cliente 1')
  const [clausesList,setClausesList]=useState(['Claúsula 1.1','Claúsula 1.2','Claúsula 2.1','Claúsula 2.2','Claúsula 2.3','Claúsula 3.1','Claúsula 4.1','Claúsula 4.2'])
  const [explanationList,setExplanationList]=useState(['iu34rf bifr 2o4fr r 2o4fr o32fr yu3r2f g23r fp823f 234upbr 2o4fp823f 234upbr 2o4fr o32fr yu3r2f g23r fp823f 234upbr 2o4fr o32fr yu3r2f g23r fp823f 234upbr 2o4fr o32fr yu3r2f g23r fp823f 234upbr 2o4fr o32fr yu3r2f g23r fp823f 234upbo32fr yu3r2f g23r fp823f 234upbf piu3rwyfvo3qu2r yu3rv2fyug32yuif yu2ev cuyrvf i2u34fv','iu34rf bifr 2o4fr o32fr yu3r2f g23r fp823f 234upbf piu3rwyfvo3qu2r yu3rv2fyug32yuif yu2ev cuyrvf i2u34fv','iu34rf bifr 2o4fr o32fr yu3r2f g23r fp823f 234upbf piu3rwyfvo3qu2r yu3rv2fyug32yuif yu2ev cuyrvf i2u34fv','iu34rf bifr 2o4fr o32fr yu3r2f g23r fp823f 234upbf piu3rwyfvo3qu2r yu3rv2fyug32yuif yu2ev cuyrvf i2u34fv','iu34rf bifr 2o4fr o32fr yu3r2f g23r fp823f 234upbf piu3rwyfvo3qu2r yu3rv2fyug32yuif yu2ev cuyrvf i2u34fv','iu34rf bifr 2o4fr o32fr yu3r2f g23r fp823f 234upbf piu3rwyfvo3qu2r yu3rv2fyug32yuif yu2ev cuyrvf i2u34fv','iu34rf bifr 2o4fr o32fr yu3r2f g23r fp823f 234upbf piu3rwyfvo3qu2r yu3rv2fyug32yuif yu2ev cuyrvf i2u34fv','iu34rf bifr 2o4fr o32fr yu3r2f g23r fp823f 234upbf piu3rwyfvo3qu2r yu3rv2fyug32yuif yu2ev cuyrvf i2u34fv'])

  //Función para requestear la data inicial
  useEffect(() => {
    async function requestData() {
      try {
        if (!accessToken) {
          console.error('Token de acceso no disponible');
          return
        }
        const response = await axios.post(URL+'/get-cases-and-members',{},{headers: {'Authorization': `Bearer ${accessToken}`}})
        setCaseList(response.data.cases)
        setOrganization(response.data.organization)
        setAccessType(response.data.access_type)
        setFilteredCaseList(response.data.cases)
        setFilteredUsersList(response.data.members.filter(item => item !== Mail))
        setUsersList(response.data.members.filter(item => item !== Mail))
        if ((response.data.cases).length!=0){
          if (localStorage.getItem('currentCase') !== null) {
            handleSetCase(localStorage.getItem('currentCase'), response.data.organization)
          } else {
            handleSetCase(response.data.cases[0], response.data.organization)
          }
        }
        const responseUser = await axios.post(URL+'/get-organization-accesses',{organization:response.data.organization},{headers: {'Authorization': `Bearer ${accessToken}`}})
        console.log(responseUser)
        setEmployeesList(responseUser.data.organization_accesses)
        }
        catch (error) {
          console.log('Error sending the code:', error)
          if (error.response && error.response.status === 401) {
              try {
                await Auth.currentAuthenticatedUser();
                const currentSession = await Auth.currentSession();
                const newAccessToken = currentSession.getIdToken().getJwtToken();
                setAccessToken(newAccessToken)
                const response = await axios.post(URL+'/get-cases-and-members',{},{headers: {'Authorization': `Bearer ${accessToken}`}})
                setCaseList(response.data.cases)
                setFilteredCaseList(response.data.cases)
                setOrganization(response.data.organization)
                setAccessType(response.data.access_type)
                setFilteredUsersList(response.data.members.filter(item => item !== Mail))
                setUsersList(response.data.members.filter(item => item !== Mail))
                if ((response.data.cases).length!=0){
                  if (localStorage.getItem('currentCase') !== null) {
                    handleSetCase(localStorage.getItem('currentCase'), response.data.organization)
                  } else {
                    handleSetCase(response.data.cases[0], response.data.organization)
                  }
                }
                const responseUser = await axios.post(URL+'/get-organization-accesses',{organization:response.data.organization},{headers: {'Authorization': `Bearer ${accessToken}`}})
                setEmployeesList(responseUser.data.organization_accesses)
              
              } catch (refreshError) {
                console.error('Error al refrescar el token', refreshError)
                }
            }
        }
    }
    requestData()
  },[])

  const [waitingCase,setWaitingCase]=useState(false)
 
  //Función para coger información de un caso
  async function handleSetCase(caseItem,Organization) {
    setWaitingCase(true)
    setShowMenu(false)
    setCaseNameTrue(caseItem)
    setIsContract(false)
    try {
      if (!accessToken) {
        console.error('Token de acceso no disponible')
        return
      } 
      const response = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseItem},{headers: {'Authorization': `Bearer ${accessToken}`}})
      setDocumentsList(response.data.document_names)
      setDocumentsDates(response.data.document_dates)
      setDocumentsSummaries(response.data.document_summaries)
      setDocumentsUrls(response.data.document_urls)
      setDocumentsType(response.data.document_types)
      setConsultList(response.data.consults)
      setConsultDates(response.data.consult_dates)
      setConsultIds(response.data.consult_ids)    
      setCaseUsers(response.data.users)
      setCaseUsersType(response.data.user_types)

      setTimeout(function () {
      setWaitingCase(false)}, 1000);
      try {
        localStorage.setItem('currentCase', caseItem);
      } catch (e) {console.error('Error while setting item in localStorage:', e)}
    }
      catch (error) {
        console.log('Error sending the code:', error)
        if (error.response && error.response.status === 401) {
            try {
              await Auth.currentAuthenticatedUser();
              const currentSession = await Auth.currentSession();
              const newAccessToken = currentSession.getIdToken().getJwtToken()
              setAccessToken(newAccessToken)
              const response = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseItem},{headers: {'Authorization': `Bearer ${accessToken}`}})
              setDocumentsList(response.data.document_names)
              setDocumentsDates(response.data.document_dates)
              setDocumentsSummaries(response.data.document_summaries)
              setDocumentsType(response.data.document_types)
              setDocumentsUrls(response.data.document_urls)
              setConsultList(response.data.consults)
              setConsultDates(response.data.consult_dates)
              setConsultIds(response.data.consult_ids)
              setCaseUsers(response.data.users)
              setCaseUsersType(response.data.user_types)
              setTimeout(function () {
    
                setWaitingCase(false)}, 1000)
              try {
                localStorage.setItem('currentCase', caseItem);
              } catch (e) {console.error('Error while setting item in localStorage:', e)} 
            } catch (refreshError) {
              console.error('Error al refrescar el token', refreshError)  
              }
          }}}

    //Función para coger información de un caso
    async function handleSetContract(caseItem,Organization) {
      setWaitingCase(true)
      setShowMenu(false)
      setContractNameTrue(caseItem)
      setIsContract(true)
      try {
        if (!accessToken) {
          console.error('Token de acceso no disponible')
          return
        } 
        const response = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseItem},{headers: {'Authorization': `Bearer ${accessToken}`}})
        setTimeout(function () {
        setWaitingCase(false)}, 1000);
        try {
          localStorage.setItem('currentCase', caseItem);
        } catch (e) {console.error('Error while setting item in localStorage:', e)}
      }
        catch (error) {
          console.log('Error sending the code:', error)
          if (error.response && error.response.status === 401) {
              try {
                await Auth.currentAuthenticatedUser();
                const currentSession = await Auth.currentSession();
                const newAccessToken = currentSession.getIdToken().getJwtToken()
                setAccessToken(newAccessToken)
                const response = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseItem},{headers: {'Authorization': `Bearer ${accessToken}`}})
                setTimeout(function () {
                  setWaitingCase(false)}, 1000)
                try {
                  localStorage.setItem('currentCase', caseItem);
                } catch (e) {console.error('Error while setting item in localStorage:', e)} 
              } catch (refreshError) {
                console.error('Error al refrescar el token', refreshError)  
                }
            }}}

  //Lista de casos filtrada
  const [filteredCaseList, setFilteredCaseList] = useState(CaseList)
  const handleSearchCases = (e) => {
    const term = e.target.value;
    const filteredList = CaseList.filter((dic) =>dic.toLowerCase().includes(term.toLowerCase()))
    setFilteredCaseList(filteredList)}

  //Lista de contratos filtrados
  const [filteredContractsList, setFilteredContractsList] = useState(ContractsList)
  const handleSearchContracts = (e) => {
    const term = e.target.value;
    const filteredList = ContractsList.filter((dic) =>dic.toLowerCase().includes(term.toLowerCase()))
    setFilteredContractsList(filteredList)}

  //Lista de ususarios filtrada
  const [filteredUsersList,setFilteredUsersList]=useState(usersList)
  const handleSearchUsers = (e) => {
    const term = e.target.value;
    const filteredList = usersList.filter((user) =>user.Username.toLowerCase().includes(term.toLowerCase()))
    setFilteredUsersList(filteredList)}

  //MenuBar
  const [showMenu,setShowMenu]=useState(false)
  const [showSearch,setShowSearch]=useState(false)
  const [showSearchContracts,setShowSearchContracts]=useState(false)

  //Función para ver si se clica fuera de la menubar
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isCreateSearchButton = event.target.closest('.createSearchButton')
      const isCreateHideButton = event.target.closest('.createHideButton')
      if (showMenu && !event.target.closest('.showMenuContainer')&&!event.target.closest('.showCaseContainer')&&!event.target.closest('.deleteFileButton')&& !event.target.closest('.showAdminContainer')&&!event.target.closest('.createButton')&&!event.target.closest('.buttonAdmin')&& !event.target.closest('.buttonInvite')&&!event.target.closest('input[type="file"]') && !isCreateSearchButton && !isCreateHideButton) {
        setShowMenu(false)}}
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    }
  }, [showMenu, setShowMenu])

  //Admin
  const [showAdmin,setShowAdmin]=useState(false)
  //Función para ver si se clica fuera de la caja de admin
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showAdmin && !event.target.closest('.showAdminContainer')&&!event.target.closest('.buttonInvite')) {
        setShowAdmin(false)
        setShowInvite(false)
      }}
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    }
  }, [showAdmin, setShowAdmin])
 
  //Invitar a ususario
  const [showInvite,setShowInvite]=useState(false)
  const [inviteMail,setInviteMail]=useState('')
  const [inviteAdmin,setInviteAdmin]=useState('Guest')
  const [InviteAccessKey,setInviteAccessKey]=useState('')

  //Función para ecibir código del ususario nuevo
  const handleReceiveCode= async ()=>{
    try {
      if (!accessToken) {
        console.error('Token de acceso no disponible');
        return
      }
      console.log({organization:Organization,invited_username:inviteMail,access_type:inviteAdmin})
      const response = await axios.post(URL+'/new-organization-key',{organization:Organization,invited_username:inviteMail,access_type:inviteAdmin},{headers: {'Authorization': `Bearer ${accessToken}`}})
      setCreateCase(false)
      setShowAdmin(false)
      setShowInvite(false)
      setShowMenu(false)
      setInviteAccessKey(response.data.access_key)
      }
      catch (error) {
        console.log('Error sending the code:', error.response.data)
        if (error.response && error.response.status === 401) {
            try {
              await Auth.currentAuthenticatedUser();
              const currentSession = await Auth.currentSession();
              const newAccessToken = currentSession.getIdToken().getJwtToken();
              setAccessToken(newAccessToken)
              console.log({organization:Organization,invited_username:inviteMail,access_type:inviteAdmin})
              const response = await axios.post(URL+'/new-organization-key',{organization:Organization,invited_username:inviteMail,access_type:inviteAdmin},{headers: {'Authorization': `Bearer ${accessToken}`}})
              setCreateCase(false)
              setShowAdmin(false)
              setShowInvite(false)
              setShowMenu(false)
              setInviteAccessKey(response.data.access_key)
            } catch (refreshError) {
              console.error('Error al refrescar el token', refreshError)
              }
          }
      }}

      async function uploadFile(preSignedUrl, file) {
        console.log(file)
        try {
          const response = await fetch(preSignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
              "x-amz-meta-vectorized": "false",
              "x-amz-meta-vectorizing": "false"
            },
            body: file
          })
          console.log(response)
          if (response.ok) {
            console.log("Document uploaded successfully!");
          } else {
            console.log(response)
            console.error("Error uploading document:", response.statusText);
          }
        } catch (error) {
          console.error("Error uploading document:", error);
        }
      }

  //Crear caso
  const [createCaseNew,setCreateCaseNew]=useState(false)
  const [createCase,setCreateCase]=useState(false)
  const [caseName,setCaseName]=useState('')
  const [showCollaborators,setShowColaborators]=useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])

  //Función para crear caso
  const handleCreateCase= async ()=>{
    setWaitingNewCase(true)
    try {
      if (!accessToken) {
        console.error('Token de acceso no disponible');
        return
      }
    const fileNames = selectedFiles.map((file) => file.name)
    console.log({organization:Organization,case:caseName,document_names:fileNames})
    const response = await axios.post(URL+'/pre-signed-urls',{organization:Organization,case:caseName,document_names:fileNames},{headers: {'Authorization': `Bearer ${accessToken}`}})
    const preSignedUrls=response.data['pre_signed_urls']
    for (let i = 0; i < preSignedUrls.length; i++) {
      const preSignedUrl = preSignedUrls[i]
      const file = selectedFiles[i]
      console.log(file)
      await uploadFile(preSignedUrl, file)
      console.log(`Uploaded ${file.name} to S3`)
    } 
    const accessTypes = employeesList
    .filter(diccionario => colaboratorList.includes(diccionario.Username))
    .map(diccionario => diccionario.AccessType)

    console.log({organization:Organization,case:caseName,invited_users:colaboratorList,invited_users_accesses:accessTypes,document_names:fileNames})
    const responseCase = await axios.post(URL+'/new-case',{organization:Organization,case:caseName,invited_users:colaboratorList,invited_users_accesses:accessTypes,document_names:fileNames},{headers: {'Authorization': `Bearer ${accessToken}`}})
    setCaseList([...CaseList, caseName])
    setFilteredCaseList([...CaseList, caseName])
    setWaitingNewCase(false)
    setSelectedFiles([])
    setCreateCase(false)
    await handleSetCase(caseName,Organization)
    setCaseName('')
    setShowMenu(false)
    setCreateCaseNew(false)
    const responseLoad = await axios.post(URL+'/process-documents',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
    }
    catch (error) {
      console.log('Error sending the code:', error)
      if (error.response && error.response.status === 401) {
          try {
            await Auth.currentAuthenticatedUser()
            const currentSession = await Auth.currentSession()
            const newAccessToken = currentSession.getIdToken().getJwtToken()
            setAccessToken(newAccessToken)
            const fileNames = selectedFiles.map((file) => file.name)
            console.log({organization:Organization,case:caseName,document_names:fileNames})
            const response = await axios.post(URL+'/pre-signed-urls',{organization:Organization,case:caseName,document_names:fileNames},{headers: {'Authorization': `Bearer ${accessToken}`}})
            const preSignedUrls=response.data['pre_signed_urls'] 
            for (let i = 0; i < preSignedUrls.length; i++) {
              const preSignedUrl = preSignedUrls[i]
              const file = selectedFiles[i]
              console.log(file)
              await uploadFile(preSignedUrl, file)
              console.log(`Uploaded ${file.name} to S3`)
            }
            const accessTypes = employeesList
            .filter(diccionario => colaboratorList.includes(diccionario.Username))
            .map(diccionario => diccionario.AccessType)
            console.log({organization:Organization,case:caseName,invited_users:colaboratorList,invited_users_accesses:accessTypes,document_names:fileNames})
            const responseCase = await axios.post(URL+'/new-case',{organization:Organization,case:caseName,invited_users:colaboratorList,invited_users_accesses:accessTypes,document_names:fileNames},{headers: {'Authorization': `Bearer ${accessToken}`}})
            setCaseList([...CaseList, caseName])
            setFilteredCaseList([...CaseList, caseName])
            setWaitingNewCase(false)
            setSelectedFiles([])
            setCreateCase(false)
            await handleSetCase(caseName,Organization)
            setCaseName('')
            setShowMenu(false)
            setCreateCaseNew(false)
            const responseLoad = await axios.post(URL+'/process-documents',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
            console.log(responseLoad.data)
          } catch (refreshError) {
            console.error('Error al refrescar el token', refreshError)
            }
        }
        else{
          setWaitingNewCase(false)
        }
    }}
  
  //Función para ver si se clica fuera de la caja de caso
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (createCase && !event.target.closest('.showCaseContainer') && !event.target.closest('.deleteFileButton') && !event.target.closest('input[type="file"]' )) {
        setCreateCase(false)
      }
    }
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    }
  }, [createCase, setCreateCase])

  //Función para ver si se clica fuera de la caja del primer caso creado nuevp
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (createCaseNew && !event.target.closest('.showCaseNewContainer')  && !event.target.closest('input[type="file"]')&&!event.target.closest('.caseSettingsBox') ) {
        setCreateCaseNew(false)
        setShowCaseSettings(false)
      }
    }
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    }
  }, [createCaseNew, setCreateCaseNew])
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showCaseSettings &&!event.target.closest('.caseSettingsBox') ) {
        setShowCaseSettings(false)
        setShowAddUsers(false)
        setUserAdded('')
        setUserDeleted('')
        setCaseDeleted('')
      }
    }
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    }
  }, [showCaseSettings, setShowCaseSettings])
  
  //Función para eliminar archivos
  const handleRemoveFile = (index) => {
      setSelectedFiles((prevSelected) => prevSelected.filter((_, i) => i !== index))}
    //Función para seleccionar archivos
    const handleFileSelect = async (e) => {
      const files = e.target.files
      if (files) {
        const selectedFilesArray = Array.from(files)
        const fileDataArray = []
        for (const file of selectedFilesArray) {
          console.log(file)
            fileDataArray.push(file)
        }
        setSelectedFiles((prevSelected) => [...prevSelected, ...fileDataArray])
      }
    }
 
 const notAddedUsers=usersList.filter(item => !caseUsers.includes(item))
 const matchingEmployees = employeesList.filter(employee => {
  return notAddedUsers.some(username => username === employee.Username);
})
const accessTypesNotAddedUsers = matchingEmployees.map(employee => employee.AccessType);
 
 
  return(
      <ChakraProvider theme={theme}>  
      {Organization==''?<>
      <Flex height={'100vh'} justifyContent={'center'} alignItems={'center'}> <Spinner/></Flex></>:
      <> 
        <Input id='selectFiles' type="file" style={{display:'none'}}  multiple  onChange={handleFileSelect} accept=".pdf"  placeholder='Selecciona archivo'/>
        <Flex>
          <Box flex={4} height={'92vh'}>
              <Flex flexDirection={'column'}> 
              <Flex  px={'2vw'} bg='gray.200' height={'8vh'}  boxShadow={'0px 1px 1px rgba(0, 0, 0, 0.3)'}justifyContent={'space-between'} alignItems={'center'} >
                  <Flex gap={'2vw'}  alignItems={'center'} >
                    <IconButton aria-label='menu' icon={<GiHamburgerMenu/>}  size='lg'  variant={'ghost'}  colorScheme='color.500' onClick={()=>setShowMenu(true)}/> 
                    <Text fontWeight={'normal'} fontSize={'xl'} ml='3vw'>{Organization} / <strong> {isContract?contractNameTrue:caseNameTrue} </strong></Text>
                  </Flex>
                  <Flex  gap={10}> 
                  {!caseNameTrue || isContract?null:<> 
                    <Button fontSize='md' textDecoration={section=='Documentos'?'underline':null}fontWeight='bold' variant={'link'}size='sm' colorScheme={'color'}   onClick={()=>{setSection('Documentos');localStorage.setItem('section','Documentos')}} >Documentos</Button>
                    <Button fontSize='md' textDecoration={section=='Consultas'?'underline':null}fontWeight='bold' variant={'link'}size='sm' colorScheme={'color'}   onClick={()=>{setSection('Consultas');localStorage.setItem('section','Consultas')}} >Consultas</Button>
                    <Button fontSize='md'isDisabled textDecoration={section=='Informes'?'underline':null}fontWeight='bold' variant={'link'}size='sm' colorScheme={'color'}  onClick={()=>{setSection('Informes');localStorage.setItem('section','Informes')}} >Informes</Button>
                     </>}
                    <IconButton aria-label='settings' zIndex={110} icon={<IoMdSettings/>} size='lg'  variant={'ghost'}  colorScheme='color.500' onClick={()=>setShowCaseSettings(true)}/>

                  </Flex>
              </Flex>
              {waitingCase?
              <Flex justifyContent={'center'} alignItems={'center'} height={'92vh'} width={'100vw'}><Spinner /></Flex>:<> 
              {CaseList.length==0&&ContractsList.length==0?
                <Flex height={'92vh'} justifyContent={'center'}  > 
                <Button mt='10vh' leftIcon={<AiOutlinePlus/>} colorScheme='color' onClick={()=>setCreateCaseNew(true)} zIndex={98}>Crear un caso</Button>
              </Flex>
                :
                <>
              
              <Box>
                  {section=='Documentos'&&<Documents accessToken={accessToken} URL={URL} Organization={Organization} handleSetCase={handleSetCase} setAccessToken={setAccessToken} documentsList={documentsList} setDocumentsList={setDocumentsList} documentsDates={documentsDates} setDocumentsDates={setDocumentsDates} documentsUrls={documentsUrls} setDocumentsUrls={setDocumentsUrls} caseName={caseNameTrue} documentsTypes={documentsTypes} setDocumentsType={setDocumentsType}/>}
                  {section=='Consultas'&&<Questions caseName={caseNameTrue} accessToken={accessToken} URL={URL} consultList={consultList} setConsultList={setConsultList} consultDates={consultDates} setConsultDates={setConsultDates} setConsultIds={setConsultIds} consultIds={consultIds} Organization={Organization} setAccessToken={setAccessToken}/>}
                  {section=='Informes'&&<Informs/>}
              </Box>
              </>
              }
              </>}
              </Flex>
          </Box>
          
      </Flex>
      </>}
      <AnimatePresence>
        {showCaseSettings&&<> 
           <Box borderRadius={'md'} bg='white'  py='3vh' px='2vw'color='color.500' position={'absolute'} top='6vh' right={'3vw'} zIndex={100} className='caseSettingsBox'>
            <Text  fontWeight={'bold'} fontSize={'lg'}>{caseNameTrue}</Text>
            <Divider mt='1vh'/>
            <Text mt='2vh' fontWeight={'bold'}>Usuarios activos:</Text>
            <Box maxHeight={'20vh'} overflowY={'scroll'}> 
            {caseUsers.map((user, index) => 
            <Flex  p={'0.8vh'} ml='1vh'alignItems={'center'} justifyContent={'space-between'} gap={'1vw'} borderRadius={'md'} >
              <Flex  alignItems={'flex-end'} gap='1vh'> 
                <Text>{user}</Text>
                <Box bg='gray.100' py={'0.2vh'} px='0.2vw' borderRadius={'md'} borderWidth={'1px'} borderColor={'gray.300'} color='color.500'> 
                <Text fontSize={'9px'}>{caseUsersType[index]}</Text>
                </Box>
              </Flex>
              <IconButton aria-label='delete-user' size='xs' variant='ghost' colorScheme='red'icon={<BsFillTrash3Fill/>} onClick={()=>setUserDeleted(user)}/>
            </Flex>
            )}</Box>
            <Button mt='3vh'leftIcon={<BsPersonFillAdd/>} colorScheme='color' size='xs' onClick={()=>setShowAddUsers(!showAddUsers)}>Añadir colaboradores</Button>
              {showAddUsers&&  <>
               <Box mt='1vh' maxHeight={'11vh'} overflowY={'scroll'} borderRadius={'xl'}> 
               {notAddedUsers.map((doc, index) => (
                   <Box>
                       <Flex  gap={'1vh'}py={'0.4vh'} borderRadius={'md'} color='color.500'> 
                       <IconButton aria-label='add-user' icon={<GrAdd/>} size='xs' variant={'ghost'} onClick={()=>{setUserAdded(doc);setUserAddedType(accessTypesNotAddedUsers[index])}}/>
                       <Flex  alignItems={'flex-end'} gap='1vh'> 
                        <Text>{doc}</Text>
                        <Box bg='gray.100' py={'0.2vh'} px='0.2vw' borderRadius={'md'} borderWidth={'1px'} borderColor={'gray.300'} color='color.500'> 
                        <Text fontSize={'9px'}>{accessTypesNotAddedUsers[index]}</Text>
                        </Box>
                      </Flex>
                       </Flex>
                   </Box>
               ))}
               </Box>
                </>}
                <Flex mt='5vh' flexDir={'row-reverse'}> 
                <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' leftIcon={<BsFillTrash3Fill/>} onClick={()=>setCaseDeleted(caseNameTrue)}>Eliminar caso</Button>  
                </Flex>
                </Box>
            <AnimatePresence>
            {userAdded!=''&&<>
            <Box bg='white' px='2vw' py='2vw' position={'absolute'}   top='40vh' borderRadius={'md'} width={'25vw'} right={'37.5vw'} zIndex={120}>
              <Text>¿Desea añadir a {userAdded} al caso "{caseNameTrue}"?</Text>
              <Flex mt='3vh' flexDir={'row-reverse'} gap='1vw'>
                <Button colorScheme='color' size='sm'  onClick={handleAddUser}>Añadir</Button>
                <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>{setUserAdded('')}}>Cerrar</Button> 
              </Flex>
              </Box>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.3 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'absolute',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 119}}>
          </motion.div></>}
          </AnimatePresence>
          <AnimatePresence>
            {userDeleted!=''&&<>
            <Box bg='white' px='2vw' py='2vw' position={'absolute'} top='40vh' borderRadius={'md'} width={'25vw'} right={'37.5vw'} zIndex={120}>
              <Text>¿Desea eliminar a {userDeleted} del caso "{caseNameTrue}"?</Text>
              <Flex mt='3vh' flexDir={'row-reverse'} gap='1vw'>
                <Button colorScheme='color' size='sm'    onClick={handleDeleteUser}>Eliminar</Button>
                <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>{setUserDeleted('')}}>Cerrar</Button> 
              </Flex>
              </Box>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.3 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'absolute',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 119}}>
          </motion.div></>}
          </AnimatePresence>
          <AnimatePresence>
            {caseDeleted!=''&&<>
            <Box bg='white' px='2vw' py='2vw' position={'absolute'} top='40vh' borderRadius={'md'} width={'25vw'} right={'37.5vw'} zIndex={120}>
              <Text>¿Desea eliminar por completo el caso "{caseDeleted}"?</Text>
              <Flex mt='3vh' flexDir={'row-reverse'} gap='1vw'>
                <Button colorScheme='color' size='sm'   onClick={handleDeleteCase}>Eliminar</Button>
                <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>{setCaseDeleted('')}}>Cerrar</Button> 
              </Flex>
              </Box>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.3 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'absolute',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 119}}>
          </motion.div></>}
          </AnimatePresence>

          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.3 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'absolute',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 99}}>
          </motion.div></>
        }
      </AnimatePresence>
      <AnimatePresence>
      {showMenu&&<> 
        <motion.div initial={{ x: -200 }}  animate={{ x: 0 }} transition={{ duration: 0.15,transition:'ease-out' }} style={{position:'absolute',top:'27.5vh', left:'5.8vw',zIndex:104}}>
          <Button leftIcon={<AiOutlinePlus/>} size='xs' colorScheme='color' onClick={()=>setCreateCase(true)} position={'absolute'} className='createButton'>Crear caso</Button>
        </motion.div>
    
        {Access_type=='Admin'&&
        <motion.div initial={{ x: -200 }}  animate={{ x: 0 }} transition={{ duration: 0.15,transition:'ease-out'}} style={{position:'absolute',top:'15.3vh', left:'2vw',zIndex:createCase?102:105 }}>
        <Button mt='2vh'leftIcon={<BsPeopleFill/>} size='xs' colorScheme='color' onClick={()=>setShowAdmin(true)} className='buttonAdmin'>Administrar usuarios</Button>
        </motion.div>}
        <motion.div initial={{ x: -200 }}  animate={{ x: 0 }} transition={{ duration: 0.15,transition:'ease-out' }} style={{position:'absolute',top:0,left:0,zIndex:101}}>
      <Box bg='white'  height={'100vh'} width='22vw' borderRadius="0px 10px 10px 0px"  py='2vh' px='2vw' className='showMenuContainer' color={'color.900'} shadow={'xl'}>
        <Flex flexDirection={'row-reverse'} alignItems={'center'} justifyContent={'space-between'} >
          <IconButton aria-label='cerrar' icon={<RxCross2/>}   size='sm'  mt='1vh'onClick={()=>setShowMenu(false)}  />
        </Flex>
        <Text mt='5vh' fontSize={'xl'}  fontWeight={'bold'} color={'color.900'}>{Organization}</Text>
        <Divider  mt='10vh'  />
     
        <Box height={'64vh'} mt='2vh'> 
        <Flex alignItems={'center'} justifyContent={'space-between'} mt='2vh' height={'3vh'}> 
          <Text fontWeight={'bold'} >Casos</Text>
          {!showSearch && <IconButton  className='createSearchButton'  aria-label='buscar' icon={<HiMiniMagnifyingGlass size='1rem'/>}   colorScheme='white' variant={'ghost'}  _hover={{bg:'gray.100'}} size={'sm'} onClick={()=>setShowSearch(true)}/>}
        </Flex>

        {showSearch&&<Flex mt='2vh' alignItems={'center'}  gap={'1vw'} >
            <Input placeholder='Filtrar casos...' focusBorderColor='#1A365D' size={'sm'} height={'4vh'}borderRadius={'md'} onChange={handleSearchCases}></Input>
            <IconButton className='createHideButton' css={{ padding: '1.1vh', minWidth: 0 }}  aria-label='esconder' height={'4.6vh'}  icon={<AiOutlineUp size={'3vh'}/>} variant={'ghost'}  px={0} onClick={()=>setShowSearch(false)}/>
        </Flex>}
            <Box mt='3vh' overflowY={'scroll'} height={'53vh'}> 
                {filteredCaseList.map((caseItem, index) => (
                  <Flex borderRadius={'md'} p={'0.9vh'} _hover={{bg:'gray.200'}} bg={caseItem==caseNameTrue?'gray.200':null}cursor={'pointer'} onClick={()=>{if(caseItem!=caseNameTrue){handleSetCase(caseItem,Organization);console.log(caseItem);console.log(caseName)}else{console.log(caseName)}}}>
                      <Text>
                        {caseItem}
                      </Text>
                  </Flex>
              ))}
              </Box>
          </Box>

        <IconButton aria-label='sign-out' icon={<BiLogOut/>}  size='sm' mt='2vh' onClick={handleSignOut}/>
      </Box>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.3 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'absolute',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 99}}>
      </motion.div>
      <AnimatePresence>
      {createCase&&
      <>
        <Box className='showCaseContainer' bg='white' borderRadius={'md'} p={'1vw'} width={'24vw'} shadow='xl'position={'absolute'} top='29.7vh' left='14vw'  zIndex={104}>
          <Text >Nombre:</Text>
          <Input mt='1vh' size='sm' borderRadius={'md'} focusBorderColor='color.500' placeholder='Nombre del caso...' onChange={(e)=>setCaseName(e.target.value)}></Input>
          <Flex mt='3vh' onClick={()=>{document.getElementById('selectFiles').click()}}  height={'10vh'} borderColor={'blue.900'} borderWidth={'1px'}  borderRadius={'xl'} flexDirection={'column'}justifyContent={'center'} alignItems={'center'} cursor={'pointer'}> 
                <Icon as={BiUpload} color="color.500" boxSize={6} />
                <Text color={'color.500'} >Subir archivos</Text>
            </Flex>
            <List borderRadius={'xl'}  mt='2vh'borderColor={'blue.900'} borderWidth={'1px'} height={'18vh'} overflowY='scroll'  >
              {selectedFiles.map((file, index) => (
                <ListItem key={index}  px={3}  bg={index%2==0?'gray.200':'gray.100'}>
                  <Flex alignItems="center" justifyContent="space-between" gap={'2vw'}>
                    <Flex alignItems={'center'} gap={'1vw'}> 
                    <Icon  color='color.500' as={GrDocument} />
                    <Text style={{ wordBreak: 'break-all' }}>{file.name}</Text>
                    </Flex>
                    <IconButton size='sm'colorScheme="red"icon={<RxCross2 />} className='deleteFileButton' aria-label="Eliminar"variant={'red'} color='red'onClick={() => handleRemoveFile(index)}/>
                  </Flex>
                </ListItem>
              ))}
            </List>
            <Button mt='3vh'leftIcon={<BsPersonFillAdd/>} colorScheme='color' size='xs' onClick={()=>setShowColaborators(true)}>Añadir colaboradores</Button>
      {showCollaborators&&
            <Box mt='1vh' height={'11vh'} overflowY={'scroll'} borderRadius={'xl'}> 
            {usersList.map((doc, index) => (
                <Box mt={index!=0?'1vh':null} ml='1vw'>
                  <Collaborators name={doc} colaboratorList={colaboratorList} setColaboratorList={setColaboratorsList} addedUsers={addedUsers} setAddedUsers={setAddedUsers} showCaseSettings={showCaseSettings}/>
                </Box>
            ))}
            </Box>}
            <Flex gap={5} mt='3vh' flexDirection={'row-reverse'}> 
            <Button colorScheme='color' fontSize={'sm'} size='sm' isDisabled={caseName.length==0} onClick={handleCreateCase}  width={'7vw'} >{waitingNewCase?<Spinner/>:'Crear caso'}</Button>
            <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>setCreateCase(false)}>Cerrar</Button> 
            </Flex>
            </Box>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.5 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'fixed',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 103}}>
            </motion.div>
          </>}
          </AnimatePresence>
          </>}
          </AnimatePresence>
          <AnimatePresence> 
            {showAdmin&&
              <>
              <Button className='buttonInvite' size={'xs'} colorScheme='color' leftIcon={<BsPersonFillAdd/>} onClick={()=>setShowInvite(true)} position={'absolute'} top='24.9vh' left='26.2vw' zIndex={120}>Invitar a un usuario</Button>
                <Box className='showAdminContainer' bg='white' borderRadius={'md'} p={'1vw'} width={'24vw'} shadow='xl'position={'absolute'} top='17.2vh' left='13.5vw'  zIndex={105}>
                  <input type="text"style={{border: 'none',outline: 'none',background: 'none',boxShadow: 'none',width:'100%',marginTop:'-1vw'}} onChange={handleSearchUsers}placeholder='Filtre usuarios...'/>
                  <Divider mt='1vh' width={'24vw'} ml='-1vw' />
               
                    <Box mt='8vh' overflowY='scroll' height={'40vh'}> 
                    {usersList.length==0?
                    <Flex justifyContent={'center'}> <Text>No hay ususarios</Text></Flex>
                    :<>
                      {filteredUsersList.map((doc, index) => (
                      <Flex alignItems={'center'}  justifyContent={'space-between'} p='0.2vh' borderRadius={'md'} _hover={{bg:'gray.100'}} cursor={'pointer'} >
                        <Flex p='0.6vh' alignItems={'center'} gap={'2vw'} onClick={()=>{setInviteAccessKey(employeesList[employeesList.findIndex((employee) => employee.Username === doc)].AccessKey);setShowMenu(false);setShowAdmin(false)}}> 
            
                        <Text>{doc}</Text>
                        </Flex>
                     
                      </Flex>
                      ))}
                      </>
                    }
                    </Box>
                <Flex gap={5} mt='3vh' flexDirection={'row-reverse'}> 

                <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>setShowAdmin(false)}>Cerrar</Button> 
                </Flex>
              </Box>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.5 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'fixed',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 104}}>
                </motion.div>
                </>
              }
          </AnimatePresence>
          <AnimatePresence> 
            {showInvite &&
            <> 
              <Box className='showAdminContainer' bg='white' borderRadius={'md'} py={'2vh'} px='1vw'width={'24vw'} shadow='xl'position={'absolute'} top='25vh' left='38.5vw'  zIndex={106}>
               <Text >E-mail</Text>
                <Input mt='1vh' placeholder='E-mail...' value={inviteMail}focusBorderColor='#1A365D' size={'sm'} height={'4vh'}borderRadius={'md'} onChange={(e)=>setInviteMail(e.target.value)}></Input>
              <Text mt='2vh'>Seleccione rol</Text>
                <Flex  gap='1vw' mt='2vh'>
                <Button size='xs' bg={inviteAdmin=='Admin'?'color.500':'gray.200'} color={inviteAdmin=='Admin'?'white':'black'} _hover={{bg:'color.500',color:'white'}} onClick={()=>setInviteAdmin('Admin')}>Administrador</Button>
                <Button size='xs' bg={inviteAdmin=='Guest'?'color.500':'gray.200'} color={inviteAdmin=='Guest'?'white':'black'} _hover={{bg:'color.500',color:'white'}} onClick={()=>setInviteAdmin('Guest')}>Usuario</Button>
                </Flex  >
                <Flex gap='1vh' flexDirection={'row-reverse'} mt='5vh'>
                  <Button colorScheme='color' size='sm' isDisabled={inviteMail==''} onClick={handleReceiveCode}>Obtener código</Button>
                  <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>setShowInvite(false)}>Cerrar</Button> 
                </Flex>
              </Box> 
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.5 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'fixed',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 105}}>
              </motion.div>
              </>
              }
            </AnimatePresence>
            <AnimatePresence> 
              {InviteAccessKey!=''&&
              <>
              <Box borderRadius={'xl'} bg='white' shadow={'xl'}  zIndex={121} position={'absolute'} top={'40vh'} height='20vh'width={'25vw'} left='37.5vw'px='1vw' py='2vh'>
              <Text fontWeight={'bold'} >Clave de acceso:</Text> 
              <Flex gap='1vh' alignItems={'center'} mt='2vh'>
                <IconButton aria-label='copy' icon={<HiOutlineClipboard/>}  variant='ghost' bg='white'onClick={()=>{copyToClipboard(InviteAccessKey)}}size='sm'/>
                <Text>{InviteAccessKey}</Text>
              </Flex> 
              <Center> 
              <Button mt='3.3vh'color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>{setInviteAccessKey('');setInviteMail('');setInviteAdmin('Guest')}}>Cerrar</Button> 
              </Center>   
              </Box>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.5 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'fixed',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 105}}>
                </motion.div>
                </>
              }
              </AnimatePresence>
              <AnimatePresence>
          {createCaseNew&&<>
          <Box className='showCaseNewContainer' bg='white' borderRadius={'md'} p={'1vw'} width={'24vw'} shadow='xl'position={'absolute'} top='24vh' left='38vw'  zIndex={107}>
          <Text >Nombre:</Text>
          <Input mt='1vh' size='sm' borderRadius={'md'} focusBorderColor='color.500' placeholder='Nombre del caso...' onChange={(e)=>setCaseName(e.target.value)}></Input>
          <Flex mt='3vh'onClick={()=>{document.getElementById('selectFiles').click()}}  height={'10vh'} borderColor={'blue.900'} borderWidth={'1px'}  borderRadius={'xl'} flexDirection={'column'}justifyContent={'center'} alignItems={'center'} cursor={'pointer'}> 
                <Icon as={BiUpload} color="color.500" boxSize={6} />
                <Text color={'color.500'} >Subir archivos</Text>
            </Flex>
            <List borderRadius={'xl'}  mt='2vh'borderColor={'blue.900'} borderWidth={'1px'} height={'18vh'} overflowY='scroll'  >
              {selectedFiles.map((file, index) => (
                <ListItem key={index}  px={3}  bg={index%2==0?'gray.200':'gray.100'}>
                  <Flex alignItems="center" justifyContent="space-between" gap={'2vw'}>
                    <Flex alignItems={'center'} gap={'1vw'}> 
                    <Icon size='100px' color='color.500' as={GrDocument} />
                    <Text style={{ wordBreak: 'break-all' }}>{file.name}</Text>
                    </Flex>
                    <IconButton size='sm'colorScheme="red"icon={<RxCross2 />} className='deleteFileButton'aria-label="Eliminar"variant={'red'} color='red'onClick={() => handleRemoveFile(index)}/>
                  </Flex>
                </ListItem>
              ))}
            </List>
            <Button mt='3vh'leftIcon={<BsPersonFillAdd/>} colorScheme='color' size='xs' onClick={()=>setShowColaborators(true)}>Añadir colaboradores</Button>
        {showCollaborators&&
            <Box mt='1vh' height={'11vh'} overflowY={'scroll'} borderRadius={'xl'}> 
            {usersList.map((doc, index) => (
                <Box mt={index!=0?'1vh':null} ml='1vw'>
<Collaborators name={doc} colaboratorList={colaboratorList} setColaboratorList={setColaboratorsList} addedUsers={addedUsers} setAddedUsers={setAddedUsers} showCaseSettings={showCaseSettings}/>

                </Box>
            ))}
            </Box>}
            <Flex gap={5} mt='3vh' flexDirection={'row-reverse'}> 
            <Button colorScheme='color' fontSize={'sm'} size='sm' isDisabled={caseName.length==0} onClick={handleCreateCase} width={'7vw'}>{waitingNewCase?<Spinner/>:'Crear caso'}</Button>
            <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>setCreateCaseNew(false)}>Cerrar</Button> 
            </Flex>
            </Box>
             <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.5 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'fixed',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 97}}>
             </motion.div>
             </>}
              </AnimatePresence>

      </ChakraProvider>
  )
}

export default Content
