//REACT
import React, {useState,useEffect,useRef} from 'react'
//AUTH
import { Auth } from 'aws-amplify'
//REQUESTS
import axios from 'axios'
//PDFs
import { Document, Page } from 'react-pdf'
//FORNT
import {Flex,Box,Divider,Button,Text,Checkbox,Spinner,Center} from '@chakra-ui/react'
import "@fontsource/jost"
//ANIMACIONES
import {motion,AnimatePresence} from 'framer-motion'
//ICONOS
import {AiOutlinePlus} from 'react-icons/ai'
import {BsClipboard,BsFillTrash3Fill} from 'react-icons/bs'

import {IoMdReturnLeft} from 'react-icons/io'
import {HiOutlineDocument} from 'react-icons/hi2'
 
import './styles.css'

//Caja para las consultas
const QuestionBox=({text,date,waiting})=>{
    return(
    <Flex p={4} borderRadius={'xl'} fontFamily={'jost'} color={'white'} cursor={waiting?null:'pointer'} flexDirection={'column'} bg={waiting?'gray.400':'color.500'} height='19vh' width="21.3vw">
        <Box height={'11vh'} overflowY={'scroll'}> 
        <Text fontSize={'sm'} >{text}</Text>
        </Box>
        <Flex justifyContent={'space-between'} alignItems={'center'}mt='2vh'> 
            <Text fontSize={'xs'} ><i> {date} </i></Text>
            {waiting&&<Spinner size='xs' speed='1s'/>}
        </Flex>
    </Flex>)}

 
//Caja para los documentos
const DocumentBox=({link,description,date,selectedItems, setSelectedItems})=>{

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
          setSelectedItems((prevItems) => {
            const updatedItems = [...prevItems]
            updatedItems.push(link)
            return updatedItems
          })
        } else {
          setSelectedItems((prevItems) => {
            const updatedItems = [...prevItems]
            const itemIndex = updatedItems.findIndex((item) => item === link);
            if (itemIndex !== -1) {
              updatedItems.splice(itemIndex, 1)
            }
            return updatedItems
          })}}
      
    return(
        <Flex p={'2vh'} height={'24vh'} borderRadius={'xl'} fontFamily={'jost'} color={'white'}   flexDirection={'column'} bg='color.500'  width="19vw" fontSize={'sm'}>
            <Flex flexDirection={'row-reverse'} justifyContent={'space-between'} gap={'1vh'} > 
            <Box> 
            <Checkbox size='lg'  colorScheme='color' onChange={handleCheckboxChange}   isChecked={selectedItems.includes(link)}/>
            </Box>
            <Box> 
            <Box height={'5vh'} overflowY={'scroll'}> 
            <Text   fontSize={'xs'} style={{ wordBreak: 'break-all' }}>
                {link}
            </Text>
            </Box>
            <Box height={'10vh'} mt='0.8vh' overflowY={'scroll'}> 
           <Text  >{description}</Text>
           </Box>
            <Text mt='2vh' fontSize={'xs'}><i> {date} </i></Text>
           </Box>
           </Flex>
        </Flex>
    )
}

function pdfRead(texto) {
    if (texto.includes('/')){
    const partes = texto.split('/');
    return partes.slice(2).join('/')}
    else{
        return texto
    }
  }

//FUNCIÓN PARA LA LISTA DE LOS PDFs
const PdfListBox=({doc,handleShowPdf,index})=>{
    const [decoration,setDecoration]=useState(null)
    return(
        <span style={{ textDecoration: decoration,cursor: 'pointer',wordBreak: 'break-all'}}  onMouseEnter={() => {setDecoration('underline')}}onMouseLeave={() => {setDecoration('none')}} onClick={()=>handleShowPdf(doc,index)}>
        {pdfRead(doc)}
    </span>
    )
}

//FUNCION PRINCIPAL
const Questions=({accessToken, URL,Organization,setAccessToken, consultList,setConsultList, consultDates,setConsultDates, consultIds,setConsultIds ,caseName})=>{

    //Posición de la caja de consulta
    const [rectangle,setRectangle]=useState({top:0,bottom:0,right:0,left:0})

    //Archivos en cola
    const [waitingFiles,setWaitingFiles]=useState(null)
    //Booleano para mostrar consulta
    const [showConsulta,setShowConsulta]=useState(false)

    //Booleano para mostrar añadir una consulta
    const [showAddConsulta,setShowAddConsulta]=useState(false)

    //Consulta
    const [question,setQuestion]=useState('')

    //Eliminar consulta
    const [showDeleteConsult,setShowDeleteConsult]=useState(false)
    const [deletedId,setDeletedId]=useState('')
    const [waitingDelete,setWaitingDelete]=useState(false)

const handleDeleteConsult= async ()=>{
    setWaitingDelete(true)
    try {
        if (!accessToken) {
            console.error('Token de acceso no disponible')
            return
        }
        console.log({organization:Organization,consult_id:deletedId,case:caseName})
        const response = await axios.post(URL+'/remove-consult',{organization:Organization,consult_id:deletedId,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
        const responseData = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
        setConsultList(responseData.data.consults)
        setConsultDates(responseData.data.consult_dates)
        setConsultIds(responseData.data.consult_ids)
        setWaitingDelete(false)
        setShowConsulta(false)
        }
        catch (error) {
            console.log('Error sending the code:', error)
            if (error.response && error.response.status === 401) {
                try {
                    await Auth.currentAuthenticatedUser()
                    const currentSession = await Auth.currentSession()
                    const newAccessToken = currentSession.getIdToken().getJwtToken()
                    setAccessToken(newAccessToken)
                    const response = await axios.post(URL+'/remove-consult',{organization:Organization,consult_id:deletedId,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
                    const responseData = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
                    setConsultList(responseData.data.consults)
                    setConsultDates(responseData.data.consult_dates)
                    setConsultIds(responseData.data.consult_ids)
                    setWaitingDelete(false)
                    setShowConsulta(false)
                } catch (refreshError) {
                    console.error('Error al refrescar el token', refreshError)
                }}}
}
    const [questionTypeList,setQuestionTypeList]=useState(["Consulta Vinculante", "Consulta No Vinculante"])
 
    const handleQuestionType = (item) => {
        if (item=='Consultas'){
          if (questionTypeList.includes('Consulta Vinculante') && questionTypeList.includes('Consulta No Vinculante')) {
            const updatedList = questionTypeList.filter(type => type !== 'Consulta Vinculante' && type!=='Consulta No Vinculante')
            setQuestionTypeList(updatedList)
            handleRecommendedFiles(updatedList)
            }
          else{
            setQuestionTypeList([...questionTypeList, 'Consulta Vinculante','Consulta No Vinculante'])
            handleRecommendedFiles([...questionTypeList, 'Consulta Vinculante','Consulta No Vinculante'])
          }
      }
        else{
        if (questionTypeList.includes(item)) {
          const updatedList = questionTypeList.filter(type => type !== item)
          setQuestionTypeList(updatedList)
          handleRecommendedFiles(updatedList)
        } else {
          setQuestionTypeList([...questionTypeList, item])
          handleRecommendedFiles([...questionTypeList, item])
        }
      }}

    //Controlar la carga de documentos 
    const [stopPolling, setStopPolling] = useState(true)

    //Pdfs recomendados
    const [documentNames,setDocumentNames]=useState([])
    const [documentSummaries,setDocumentSummaries]=useState([])
    const [documentDates,setDocumentDates]=useState([])
    //Documentos seleccionados
    const [SelectedDocuments,setSelectedDocuments]=useState([])

    //Bolleano para la carga de encviar consulta
    const [waitingQuestionSend,setWaitingQuestionSend]=useState(false)

    //Booleano para la carga de una consulta
    const [waitingQuestion,setWaitingQuestion]=useState(false)

    //Consulta consultada
    const [questionBig,setQuestionBig]=useState('')

    //Texto de la respuesta general
    const [generalText,setGeneralText]=useState('')

    //Texto de la respuesta del pdf
    const [pdfText,setPdfText]=useState('')

    //Pdfs usados
    const [pdfList,setPdfList]=useState([])
    const [documentsUrls,setDocumentsUrls]=useState([])
    const [documentsAnswer,setDocumentsAnswer]=useState([])

    //Pdfs usados
    const [pdfName,setPdfName]=useState('') 
    const [pdfUrl,setPdfUrl]=useState('')

    const [numPages,setNumPages]=useState(0)
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
      }

    //Lanzar llamadas a la Api si hace un segundo que no se modifica el input
    const [timer, setTimer] = useState(null)
    async function handleRecommendedFiles(questionTypeList) {
        setWaitingQuestion(true)
        try {
        if (!accessToken) {
            console.error('Token de acceso no disponible')
            return
        }
        console.log({organization:Organization,consult:question,case:caseName,valid_document_types:questionTypeList})
        const response = await axios.post(URL+'/get-recommended-documents',{organization:Organization,consult:question,case:caseName,valid_document_types:questionTypeList},{headers: {'Authorization': `Bearer ${accessToken}`}})
        console.log(response.data)
        setDocumentNames(response.data.document_names.map((documentName) => {return pdfRead(documentName)})) 
        setDocumentSummaries(response.data.document_summaries)
        const selectedDocumentNames = response.data.document_names.map((documentName) => {return pdfRead(documentName)}).filter((name, index) => {
            return response.data.is_document_selected[index] === true
          })
        setSelectedDocuments(selectedDocumentNames)
        setDocumentDates(response.data.document_dates)
        setWaitingQuestion(false)
        }
        catch (error) {
            console.log('Error sending the code:', error)
            if (error.response && error.response.status === 401) {
                try {
                    await Auth.currentAuthenticatedUser()
                    const currentSession = await Auth.currentSession()
                    const newAccessToken = currentSession.getIdToken().getJwtToken()
                    setAccessToken(newAccessToken)
                    const response = await axios.post(URL+'/get-recommended-documents',{organization:Organization,consult:question,case:caseName,valid_document_types:questionTypeList},{headers: {'Authorization': `Bearer ${accessToken}`}})
                    setDocumentNames(response.data.document_names.map((documentName) => {return pdfRead(documentName)})) 
                    setDocumentSummaries(response.data.document_summaries)
                    const selectedDocumentNames = response.data.document_names.map((documentName) => {return pdfRead(documentName)}).filter((name, index) => {
                        return response.data.is_document_selected[index] === true
                      })
                    setSelectedDocuments(selectedDocumentNames)
                    setDocumentDates(response.data.document_dates)
                    setWaitingQuestion(false)
               
                } catch (refreshError) {
                    console.error('Error al refrescar el token', refreshError)
                }}}}

    useEffect(() => {
    if (question!='') {
        if (timer) {
        clearTimeout(timer)
        }
        const newTimer = setTimeout(() => {
        handleRecommendedFiles(questionTypeList)
        }, 1500)
        setTimer(newTimer)
    }
    }, [question])


    //Enviar consulta
    async function handleSendQuestion() {
        setWaitingQuestionSend(true)
        try {
        if (!accessToken) {
            console.error('Token de acceso no disponible')
            return
        }
        console.log({organization:Organization,consult:question,case:caseName,document_names:SelectedDocuments})
        const questionApi=question
        const selectedDocumentsApi=SelectedDocuments
        setQuestion('')
        setDocumentNames([])
        setDocumentSummaries([])
        setDocumentDates([])
        setStopPolling(true)
        const response = axios.post(URL+'/post-consult',{organization:Organization,consult:questionApi,case:caseName,document_names:selectedDocumentsApi},{headers: {'Authorization': `Bearer ${accessToken}`}})
        console.log({organization:Organization,case:caseName})
        const responseCase = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
        setConsultList(responseCase.data.consults)
        setConsultDates(responseCase.data.consult_dates)
        setConsultIds(responseCase.data.consult_ids)
         setTimeout(() => {
            setWaitingQuestionSend(false)
            setShowAddConsulta(false)
        }, 2000)
        }
        catch (error) {
            console.log('Error sending the code:', error)
            if (error.response && error.response.status === 401) {
                try {
                    await Auth.currentAuthenticatedUser();
                    const currentSession = await Auth.currentSession();
                    const newAccessToken = currentSession.getIdToken().getJwtToken()
                    setAccessToken(newAccessToken)
                    const questionApi=question
                    const selectedDocumentsApi=SelectedDocuments
                    setQuestion('')
                    setDocumentNames([])
                    setDocumentSummaries([])
                    setDocumentDates([])
                    setStopPolling(true)
                    const response = axios.post(URL+'/post-consult',{organization:Organization,consult:questionApi,case:caseName,document_names:selectedDocumentsApi},{headers: {'Authorization': `Bearer ${accessToken}`}})
                    const responseCase = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
                    setConsultList(responseCase.data.consults)
                    setConsultDates(responseCase.data.consult_dates)
                    setConsultIds(responseCase.data.consult_ids)
                    setTimeout(() => {
                        setWaitingQuestionSend(false)
                        setShowAddConsulta(false)
                    },2000)
                } catch (refreshError) {
                    console.error('Error al refrescar el token', refreshError)
                }}}
    }

    //Función que triggerea la animación de la aparición de la caja
    async function handleBoxClick (event, index) {
        const box = event.target.getBoundingClientRect() 
        console.log(box)
        try {
            if (!accessToken) {
                console.error('Token de acceso no disponible')
                return
            }
            console.log({organization:Organization,consult_id:consultIds[index],case:caseName})
            const response = await axios.post(URL+'/get-consult',{organization:Organization,consult_id:consultIds[index],case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})  
            setQuestionBig(consultList[index])
            setDeletedId(consultIds[index])
            setGeneralText((response.data.general_answer).replace(/\n/g, "<br />"))
            setPdfList(response.data.document_names)
            setDocumentsUrls(response.data.document_urls)
            setDocumentsAnswer(response.data.document_answers)
            setRectangle({bottom:box.bottom,left:box.left,right:box.right,top:box.top})
            setShowConsulta(true)
        }
        catch (error) {
            console.log('Error sending the code:', error)
            if (error.response && error.response.status === 401) {
                try {
                    await Auth.currentAuthenticatedUser();
                    const currentSession = await Auth.currentSession();
                    const newAccessToken = currentSession.getIdToken().getJwtToken();
                    setAccessToken(newAccessToken)
                    const response = await axios.post(URL+'/get-consult',{organization:Organization,consult_id:consultIds[index],case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
                    setQuestionBig(consultList[index])
                    setDeletedId(consultIds[index])
                    setGeneralText((response.data.general_answer).replace(/\n/g, "<br />"))
                    setPdfList(response.data.document_names)
                    setDocumentsUrls(response.data.document_urls)
                    console.log(response.data.document_answer)
                    setDocumentsAnswer(response.data.document_answers)
                    setRectangle({bottom:box.bottom,left:box.left,right:box.right,top:box.top})
                    setShowConsulta(false)
                } catch (refreshError) {
                    console.error('Error al refrescar el token', refreshError)
        }}}
    }

    //Mostrar pdf
   const handleShowPdf=(doc,index)=>{
    setPdfText(documentsAnswer[index].replace(/\n/g, "<br />"))
    setPdfName(doc)
    setPdfUrl(documentsUrls[index])
   }
    
   //LLamar para ver el estado de las cajas
   let intervalId
   useEffect(() => {
     const fetchData = async () => {
       try {
         if (!accessToken) {
           console.error('Token de acceso no disponible')
           return
         }
         const response = await axios.post(URL+'/get-processing-consults',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
         setWaitingFiles(response.data.processing_consults)
         if ((response.data.processing_documents).length === 0) {
           setStopPolling(false)
           clearInterval(intervalId)
         }

       } catch (error) {
         if (error.response && error.response.status === 401) {
           try {
             await Auth.currentAuthenticatedUser()
             const currentSession = await Auth.currentSession()
             const newAccessToken = currentSession.getIdToken().getJwtToken()
             const response = await axios.post(URL+'/get-processing-consults',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
             setWaitingFiles(response.data.processing_consults)
             if ((response.data.processing_documents).length === 0) {
               setStopPolling(false)
               clearInterval(intervalId)
             }
    
           } catch (refreshError) {
             console.error('Error al refrescar el token', refreshError)
             }
         }
       }
     }
     if (stopPolling) {
       fetchData();
       const intervalId = setInterval(fetchData, 2000);
       return () => {
         clearInterval(intervalId)
       }
     }
   }, [stopPolling])

  

    //Copiar texto
    const copyToClipboard=(text)=> {
        navigator.clipboard.writeText(text)
            .then(() => {
            alert('Texto copiado');
            })
            .catch((error) => {
            console.error('Error al copiar el texto: ', error);
            })
        }

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (showAddConsulta && !event.target.closest('.addConsultContainer') ) {
            setQuestion('')
            setDocumentNames([])
            setDocumentSummaries([])
            setDocumentDates([])
            setWaitingQuestionSend(false)
            setStopPolling(true)
            setShowAddConsulta(false)
          }
        }
        document.body.addEventListener('click', handleClickOutside)
        return () => {
          document.body.removeEventListener('click', handleClickOutside)
        }
      }, [showAddConsulta, setShowAddConsulta])

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (showDeleteConsult && !event.target.closest('.deleteConsult') ) {
            setShowDeleteConsult(false)
            setDeletedId('')
          }
        }
        document.body.addEventListener('click', handleClickOutside)
        return () => {
          document.body.removeEventListener('click', handleClickOutside)
        }
      }, [showDeleteConsult, setShowDeleteConsult])
     
    // Calcula la posición inicial del componente
    const initialX = rectangle.left-10
    const initialY = rectangle.top-70

    return(
    <> 
    <AnimatePresence> 
    {showConsulta&&
    <> 
        <AnimatePresence> 
    {showDeleteConsult &&<>
     <Box bg='white' px='2vw' py='2vw' position={'absolute'}   className='deleteConsult'top='40vh' borderRadius={'md'} width={'25vw'} right={'37.5vw'} zIndex={120}>
        <Center> 
     <Text>¿Desea eliminar la consulta?</Text>
     </Center>
     <Flex mt='3vh' flexDir={'row-reverse'} gap='1vw'>
       <Button colorScheme='color' size='sm'  onClick={handleDeleteConsult} width={'6vw'}>{waitingDelete?<Spinner/>:'Eliminar'}</Button>
       <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>{setShowDeleteConsult(false);setDeletedId('')}}>Cerrar</Button> 
     </Flex>
     </Box>
     <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.3 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'absolute',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 119}}>
 </motion.div></>}
    </AnimatePresence> 
    <motion.div style={{ backgroundColor: '#1A365D',opacity:0.2,position:'absolute' ,zIndex:98, overflow: 'hidden'}}initial={{opacity:1, x: initialX,y: initialY,width: '21vw',height: '18vh',borderRadius:'20px'}}animate={{x:0,y:'2px',width: '100vw',height: '91vh',background: '#EDF2F7',borderRadius:0}}transition={{ duration: 0.35, ease: 'easeOut' }}>
        <Flex  py={'2vw'} flexDirection={'column'}>
            <Flex height={'9vh'} flexDirection={'row-reverse'} px='3vw' gap='8vw'> 
            <Box flex={1}> 
                <Box> 
                <Button width='100%' onClick={()=>{setShowConsulta(false);setPdfUrl('');setPdfName('')}}  colorScheme='color' size='sm' leftIcon={<IoMdReturnLeft/>}  >Volver a consultas</Button>
 
                </Box>
                <Box mt='2vh'>
                <Button color='red'width='100%'borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' leftIcon={<BsFillTrash3Fill/>} onClick={()=>setShowDeleteConsult(true)}>Eliminar consulta</Button>  
                </Box>
             </Box>
                    <Box overflowY={'scroll'} flex={6}>
                        <Text fontSize={'xl'} fontWeight={'bold'}>{questionBig}</Text>
                    </Box>
                </Flex>
                <Flex gap={'3vw'} px={'3vw'} mt='3vw'> 
                    <Box flex={5}> 
                        <Flex justifyContent={'space-between'}> 
                            <Flex gap={'1vw'}> 
                                <Text fontWeight={'bold'}>Respuesta general</Text>
                                <Button size='xs' leftIcon={<BsClipboard/>} fontWeight={'normal'} bg='color.500' color='white' _hover={{color:'white',bg:'color.500'}} onClick={()=>{copyToClipboard(generalText)}}>Copiar</Button>
                            </Flex>
                        </Flex>
                        <Box mt='2vh' borderRadius={'md'} borderColor={'color.500'} borderWidth={'1px'} height={'65vh'} px='1vw' py='2vh' overflowY={'scroll'}>
                            <Text  dangerouslySetInnerHTML={{ __html: generalText }}/>
                        </Box>
                    </Box>
                    <Box flex={5}>
                        <Flex justifyContent={'space-between'}> 
                            <Flex gap='1vw' alignItems={'flex-end'}> 
                                <Text fontWeight="bold" fontSize={pdfName ? 'sm' : 'md'} style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {pdfName ? pdfRead(pdfName) : 'Documentos'}</Text>
                                {pdfName&&<Button size='xs' leftIcon={<BsClipboard/>} fontWeight={'normal'} bg='color.500' color='white' _hover={{color:'white',bg:'color.500'}} onClick={()=>{copyToClipboard(pdfText)}}>Copiar</Button>}
                            </Flex>
                            {pdfName&&<Button colorScheme='color' size='xs' onClick={()=>setPdfName('')} leftIcon={<HiOutlineDocument color='white'/>}>Documentos</Button>}
                        </Flex>
                        <Box mt='2vh' borderRadius={'md'} borderColor={'color.500'} borderWidth={'1px'} height={'65vh'}overflowY={'scroll'} px='2vw' py='2vh'>
                            {pdfName?
                                  <Text  dangerouslySetInnerHTML={{ __html: pdfText }}/>
                                    :<>
                                {pdfList.map((doc,index)=>(
                                    <Box mt={index!=0?'1vh':null} >
                                        <PdfListBox doc={doc} index={index}  handleShowPdf={handleShowPdf}/>
                                    </Box>
                                ))}</>}
                        </Box>  
                    </Box>
                    <Box flex={5}> 
                        <Flex justifyContent={'space-between'}> 
                            <Flex gap={'1vw'}> 
                            <Text fontWeight={'bold'}>Visualizador de pdf</Text>
                       
                            </Flex>
                        </Flex>
                            {pdfUrl? 
                            <div style={{ overflowY: 'scroll', height: '65vh',marginTop:'2vh',borderRadius:'0.375rem',position:'sticky',borderWidth:'1px',borderColor:'#1A365D'}}>
                                     <style>{`.react-pdf__message {display: none !important;}`}</style>
                                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                                    {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index+1}  width={ window.innerWidth*0.28}/>
                                    ))}
                                </Document>
                            </div>:
                        <Box mt='2vh' borderRadius={'md'} borderColor={'color.500'} borderWidth={'1px'} height={'65vh'}>
                        </Box>}
                    </Box>
                </Flex>
            </Flex>
            </motion.div>
            </>}
       </AnimatePresence>

       <Flex p={10} flexDirection={'column'} >
       <Button leftIcon={<AiOutlinePlus/>} colorScheme='color' size='sm' width='10vw' onClick={()=>setShowAddConsulta(true)}>Añadir consulta</Button>  
       <AnimatePresence> 
            {waitingFiles==null?<> </>:
            <Box mt='4vh' height={'75vh'} overflowY={'scroll'}>      
            <Flex overflowY={'scroll'} flexWrap={'wrap'} columnGap={'3.1vw'} rowGap={'3.1vw'}> 
            {consultList.map((card, index) => (
            <motion.div className={`questionContainer${index}`}initial={{ opacity: 0 }}animate={{ opacity: 1, y: 0 }}exit={{ opacity: 0}}transition={{ duration: 0.8, delay: index * 0.1 }}onClick={(event) => {if (waitingFiles.includes(consultIds[index])) {console.log('nada');} else {handleBoxClick(event, index);}}}>
              <QuestionBox key={index} text={consultList[index]}date={consultDates[index]}waiting={waitingFiles.includes(consultIds[index])}/>
            </motion.div>
          ))}
            </Flex>
            </Box>
       }</AnimatePresence>
        </Flex>
   
    <AnimatePresence> 
        {showAddConsulta&&<> 
        <Box className='addConsultContainer' px={'1.5vw'} py='1.5vw'bg='white' borderRadius={'xl'} width={'65vw'} position={'absolute'} zIndex={102} top='8.3vh' right={'17.5vw'}>  
        <Box mt='-1vh'>   
          <input type="text"style={{border: 'none',outline: 'none',background: 'none',boxShadow: 'none',width:'100%',marginTop:'-1vw'}} placeholder='Escriba su consulta...' onChange={(e)=>{setQuestion(e.target.value);setWaitingQuestion(true)}}/>
          </Box>
          <Divider colorScheme='color' borderWidth={'1px'} mt='1vh' width={'64.8vw'} ml='-1.5vw'/>
            <Flex mt='3vh' gap='2vw' justifyContent={'center'}>
                <Button size='xs' bg={questionTypeList.includes('Consulta Vinculante')&&questionTypeList.includes('Consulta No Vinculante')? 'color.500': 'gray.200'} color={questionTypeList.includes('Consulta Vinculante')&&questionTypeList.includes('Consulta No Vinculante')? 'white': 'black'} _hover={{color:'white',bg:'color.500'}}onClick={()=>{handleQuestionType('Consultas')}}>Consultas</Button>
                <Button size='xs' bg={questionTypeList.includes('Sentencia del Tribunal Supremo')? 'color.500': 'gray.200'} color={questionTypeList.includes('Sentencia del Tribunal Supremo')? 'white': 'black'} _hover={{color:'white',bg:'color.500'}}onClick={()=>{handleQuestionType('Sentencia del Tribunal Supremo')}}>STS</Button>
                <Button size='xs' bg={questionTypeList.includes('Sentencia de la Audiencia Nacional')? 'color.500': 'gray.200'} color={questionTypeList.includes('Sentencia de la Audiencia Nacional')? 'white': 'black'} _hover={{color:'white',bg:'color.500'}}onClick={()=>{handleQuestionType('Sentencia de la Audiencia Nacional')}}>SAN</Button>
                <Button size='xs' bg={questionTypeList.includes('Sentencia del Tribunal Superior de Justicia')? 'color.500': 'gray.200'} color={questionTypeList.includes('Sentencia del Tribunal Superior de Justicia')? 'white': 'black'} _hover={{color:'white',bg:'color.500'}}onClick={()=>{handleQuestionType('Sentencia del Tribunal Superior de Justicia')}}>STSJ</Button>
                <Button size='xs' bg={questionTypeList.includes('Sentencia del Tribunal de Primera Instancia e Instrucción')? 'color.500': 'gray.200'} color={questionTypeList.includes('Sentencia del Tribunal de Primera Instancia e Instrucción')? 'white': 'black'} _hover={{color:'white',bg:'color.500'}}onClick={()=>{handleQuestionType('Sentencia del Tribunal de Primera Instancia e Instrucción')}}>STPI</Button>
                <Button size='xs' bg={questionTypeList.includes('Sentencia de Otro Tribunal')? 'color.500': 'gray.200'} color={questionTypeList.includes('Sentencia de Otro Tribunal')? 'white': 'black'} _hover={{color:'white',bg:'color.500'}}onClick={()=>{handleQuestionType('Sentencia de Otro Tribunal')}}>Otras sentencias</Button>
                </Flex>
          <Box height={'60vh'} overflowY={'scroll'} mt='3vh' >
            <Flex flexWrap={'wrap'} columnGap={'4vh'} rowGap={'4vh'}> 
            {waitingQuestion?
            <Flex justifyContent={'center'} height={'100%'} width='100%'alignItems={'center'}> 
            {question!=''?
            <Flex alignItems={'center'} height={'60vh'}><Spinner size='xl' color='color.500'/></Flex>:<Text>Ver documentos recomendados...</Text>}
            </Flex>:<> 
                {documentNames.map((doc, index) => (
                    <Box key={index}  >
                        <DocumentBox link={documentNames[index]} description={documentSummaries[index]} date={documentDates[index]} selectedItems={SelectedDocuments} setSelectedItems={setSelectedDocuments}/>
                    </Box>
                ))} </>}
                </Flex>
            </Box>
         
                <Flex justifyContent={'space-between'} ml='1vw'mt='3vh' alignItems={'center'}> 
                    <Text fontSize={'sm'} fontStyle={'italic'}>{(SelectedDocuments.length).toLocaleString('es-ES',  {minimumFractionDigits: 0,maximumFractionDigits: 0})} documentos, 0 páginas, 0,00 €</Text>
              
            <Flex  gap={5} flexDirection={'row-reverse'}> 
                <Button colorScheme='color' fontSize={'sm'} size='sm' onClick={handleSendQuestion} width={'9.3vw'}>{waitingQuestionSend?<Spinner/>: 'Realizar consulta'}</Button>
                <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>{setShowAddConsulta(false);setDocumentNames([]);setDocumentDates([]);setDocumentSummaries([]);setSelectedDocuments([])}}>Cerrar</Button> 
            </Flex>
            </Flex>
 
        </Box>

    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}  transition={{ duration: 0.5 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'fixed',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 101}}>
    </motion.div>
    </>}
    </AnimatePresence> 
       </>
    )
}

export default Questions