//REACT
import React, {useState,useEffect, useRef} from 'react'
//PDFs
import { Document, Page ,pdfjs} from 'react-pdf'
//AUTH
import { Auth } from 'aws-amplify'
//REQUESTS
import axios from 'axios'
//FRONT
import {Flex,Box,Button,IconButton,Text,Input,List,ListItem,Icon,Spinner, Skeleton,SkeletonText} from '@chakra-ui/react'
import "@fontsource/jost"
//ANIMACIONES
import {motion,AnimatePresence} from 'framer-motion'
//ICONOS
import {AiOutlinePlus} from 'react-icons/ai'
import {BiUpload} from 'react-icons/bi'
import {GrDocument} from 'react-icons/gr'
import {RxCross2} from 'react-icons/rx'
import './styles.css'

function pdfRead(texto) {
  const partes = texto.split('/')
  return partes.slice(2).join('/')
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const Documents=({accessToken,URL,Organization,setAccessToken,caseName,documentsList,setDocumentsList, documentsDates,setDocumentsDates,documentsUrls,setDocumentsUrls,documentsTypes,setDocumentsType,handleSetCase})=>{
  
    //Documentos
    const [waitingFiles,setWaitingFiles]=useState(null)
    const previousSize=useRef(0)
  //Controlar la carga de documentos 
  const [stopPolling, setStopPolling] = useState(true)
  //Error de carga de documentos
  const [uploadError,setUploadError]=useState('')
 
  //Archivos seleccionados para añadir
  const [selectedFiles, setSelectedFiles] = useState([])
  //Enseñar archivo clicado 
  const [showAddFile,setShowAddFile]=useState(false)

  //Booleano para controlar la subida de documentos 
  const [waitingUpload,setWaitingUpload]=useState(false)
  //Controlar las paginas con pdf-renderer
  const [numPages, setNumPages] = useState(null)
  const [pdfUrl, setPdfUrl] = useState(null) 
  const [pdfRendered,setPdfRendered]=useState(false)
 
    const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPdfRendered(true)
  }
 
  //Obtener lista de pdfs
  const handleShowPdf=(index)=>{
    console.log(index)
    setPdfUrl(documentsUrls[index])
      setPdfRendered(true)
   }

  //Función para ver si clica fuera de la caja de descargar documentos 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showAddFile && !event.target.closest('.documentsContainer') && !event.target.closest('input[type="file"]')&&!event.target.closest('.deleteFileButton')) {
        setShowAddFile(false)
        setSelectedFiles([])
      }}
    document.body.addEventListener('click', handleClickOutside) 
    return () => {
      document.body.removeEventListener('click', handleClickOutside)
    }
  }, [showAddFile, setShowAddFile])
    
 //Función para seleccionar archivos
 const handleFileSelect = async (e) => {
  const files = e.target.files
  if (files) {
    const selectedFilesArray = Array.from(files)
    const fileDataArray = []
    for (const file of selectedFilesArray) {
        fileDataArray.push(file)
    }
    setSelectedFiles((prevSelected) => [...prevSelected, ...fileDataArray])
  }}

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevSelected) => prevSelected.filter((_, i) => i !== index))}

  async function uploadFile(preSignedUrl, file) {
    console.log(file)
    try {
      const response = await fetch(preSignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
          "x-amz-meta-vectorized": "false",
          "x-amz-meta-vectorizing": "false"
        },
        body: file
      })
      console.log(response)
      if (response.ok) {
        console.log("Document uploaded successfully!");
      } else {
        console.log(response)
        console.error("Error uploading document:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  }

  //Función para subir archivos
  const handleUploadFiles= async ()=>{
    setWaitingUpload(true)
    try {
      if (!accessToken) {
        console.error('Token de acceso no disponible');
        return
      }
    const fileNames = selectedFiles.map((file) => file.name)
    console.log({organization:Organization,case:caseName,document_names:fileNames})
    const response = await axios.post(URL+'/pre-signed-urls',{organization:Organization,case:caseName,document_names:fileNames},{headers: {'Authorization': `Bearer ${accessToken}`}})
    const preSignedUrls=response.data['pre-signed-urls']
    for (let i = 0; i < preSignedUrls.length; i++) {
      const preSignedUrl = preSignedUrls[i]
      const file = selectedFiles[i]
      console.log(file)
      await uploadFile(preSignedUrl, file)
      console.log(`Uploaded ${file.name} to S3`)
    }
    const responseLoad = axios.post(URL+'/process-documents',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
    setTimeout(() => {
      setWaitingUpload(false)
      setShowAddFile(false)
      setStopPolling(true)
      setWaitingFiles(fileNames)
      setSelectedFiles([])
    }, 2000)
    }
    catch (error) {
      console.log('Error sending the code:', error)
      if (error.response && error.response.status === 401) {
          try {
            await Auth.currentAuthenticatedUser()
            const currentSession = await Auth.currentSession()
            const newAccessToken = currentSession.getIdToken().getJwtToken()
            setAccessToken(newAccessToken)
            const fileNames = selectedFiles.map((file) => file.name)
            console.log({organization:Organization,case:caseName,document_names:fileNames})
            const response = await axios.post(URL+'/pre-signed-urls',{organization:Organization,case:caseName,document_names:fileNames},{headers: {'Authorization': `Bearer ${accessToken}`}})
            const preSignedUrls=response.data['pre-signed-urls']   
            for (let i = 0; i < preSignedUrls.length; i++) {
              const preSignedUrl = preSignedUrls[i]
              const file = selectedFiles[i]
              console.log(file)
              await uploadFile(preSignedUrl, file)
              console.log(`Uploaded ${file.name} to S3`)
            }
            const responseLoad = axios.post(URL+'/process-documents',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
            setTimeout(() => {
              setWaitingUpload(false)
              setShowAddFile(false)
              setStopPolling(true)
              setWaitingFiles(fileNames)
              setSelectedFiles([])
            }, 2000)
          } catch (refreshError) {
            console.error('Error al refrescar el token', refreshError)
            }
        }
        else{
          setWaitingUpload(false)
          setUploadError(error.response.data.error)
        }
    }}

    let intervalId 
    useEffect(() => {
      const fetchData = async () => {
        try {
          if (!accessToken) {
            console.error('Token de acceso no disponible')
            return
          }
          const response = await axios.post(URL+'/get-processing-documents',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
          console.log(response.data)
          setWaitingFiles(response.data.processing_documents)
          if ((response.data.processing_documents).length < previousSize.current) {
            previousSize.current=(response.data.processing_documents).length
            console.log('LAMMAR')
            const responseCase = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
            setDocumentsList(responseCase.data.document_names)
            setDocumentsDates(responseCase.data.document_dates)
            setDocumentsType(responseCase.data.document_types)
            setDocumentsUrls(responseCase.data.document_urls)
          }
          else{
          previousSize.current=((response.data.processing_documents).length)
          if ((response.data.processing_documents).length === 0) {
            setStopPolling(false)
            clearInterval(intervalId)
          }}
        } catch (error) {
          if (error.response && error.response.status === 401) {
            try {
              await Auth.currentAuthenticatedUser()
              const currentSession = await Auth.currentSession()
              const newAccessToken = currentSession.getIdToken().getJwtToken()
              const response = await axios.post(URL+'/get-processing-documents',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
              console.log(response.data)
              console.log(response.data.processing_documents)
              console.log(previousSize.current)
              console.log((response.data.processing_documents).length)
    
              setWaitingFiles(response.data.processing_documents)
              if ((response.data.processing_documents).length < previousSize.current) {
                console.log('LAMMAR')
                const responseData = await axios.post(URL+'/get-case-data',{organization:Organization,case:caseName},{headers: {'Authorization': `Bearer ${accessToken}`}})
                setDocumentsList(responseData.data.document_names)
                setDocumentsDates(responseData.data.document_dates)
                setDocumentsType(responseData.data.document_types)
                setDocumentsUrls(responseData.data.document_urls)
              }
              else{
              previousSize.current=((response.data.processing_documents).length)
              if ((response.data.processing_documents).length === 0) {
                setStopPolling(false)
                clearInterval(intervalId)
              }}
            } catch (refreshError) {
              console.error('Error al refrescar el token', refreshError)
              }}}}
      if (stopPolling) {
        fetchData()
        const intervalId = setInterval(fetchData, 2000);
        return () => {
          clearInterval(intervalId)
        }
      }
    }, [stopPolling,caseName])
 
    console.log(pdfUrl)
    return(  
        <> 
        <Input id='selectImage' type="file" style={{display:'none'}}  multiple  onChange={handleFileSelect} accept=".pdf"  placeholder='Selecciona archivo'/>
        <Flex  fontWeight={'bold'} fontSize={'xl'} fontFamily={'jost'} >
            <Flex flex={4} flexDirection={'column'} mt='2vw' px={'3vw'}> 
            <Box> 
              <Box> 
                  <Text>Documentos</Text>
                  <Button leftIcon={<AiOutlinePlus/>} size='xs' fontSize={'xs'} colorScheme='color' onClick={()=>{setShowAddFile(true)}} mt='2vh'zIndex={98}>Añadir documentos</Button>
                </Box>
                  <List borderRadius={'md'}  mt='2vh'borderColor={'blue.900'} width='100%' borderWidth={'1px'} height={'74.5vh'} overflowY='scroll'fontSize={'sm'}   >
                  {waitingFiles!=null?<> 
                  {waitingFiles.map((file, index) => (
                       <ListItem key={index}  px={3}  bg={'gray.300'}   >
                      <Flex alignItems={'center'} justifyContent={'space-between'} gap={'2vh'}> 
                       <Flex alignItems={'center'} gap={'1vw'} py='.8vh' flex={4}> 
                       <Box> 
                        <Spinner size='xs' speed='1s'/>
                        </Box>
                         <Text color='color.500' style={{ wordBreak: 'break-all' }}>{pdfRead(file)}</Text>
                       </Flex>
                       <Box flex={3}> 
                       <Skeleton   width={'17vw'} > 4fiueb</Skeleton>
                       </Box>
                       <Box flex={1}  > 
                       <Skeleton  width={'5vw'}  ml='0.3vh'>4fiueb</Skeleton>
                       </Box>
                </Flex>
                     </ListItem> ))}
                {documentsList.map((file, index) => (
                  <ListItem key={index}  px={3}  bg={index%2==0?'gray.200':'gray.100'}>
                    <Flex alignItems={'center'} justifyContent={'space-between'} gap={'2vw'}> 
                    <Flex alignItems={'center'} gap={'1vw'} _hover={{textDecoration:'underline',cursor:'pointer'}} py='0.8vh' flex={4}> 
                      <Icon size='sm' color='color.500' as={GrDocument} />
                      <Text   onClick={()=>{handleShowPdf(index)}} style={{ wordBreak: 'break-all' }}>{pdfRead(file)}</Text>
                    </Flex>
                    <Text fontWeight={'normal'}flex={3} >{documentsTypes[index]}</Text>
                    <Text fontWeight={'normal'} flex={1}>{documentsDates[index]}</Text>
                    </Flex>
                  </ListItem>
                ))} </>:
                <Box px={'2vw'} py='2vh'> 
                  {documentsList.map((document, index) => (
                      <SkeletonText key={`skeleton_${index}`}mt={index==0?null:'2vh'} noOfLines={1} skeletonHeight='3vh'/>))}
                </Box>}
              </List>     
              </Box>            
            </Flex>
      {pdfUrl === null ? (
      <Flex  flex={3}height='92vh'bg='gray.300'flexDirection={'column'}justifyContent={'center'}alignItems={'center'}>
        <Text>Aquí se mostrará el documento seleccionado</Text>
        </Flex>
      ) : (
        <div style={{ overflowY: 'scroll', overflowX: 'hidden', display: 'flex', flex: 3, height: '91.8vh', marginTop: '0.2vh' }}>
        <style>
          {`
            .react-pdf__message {
              display: none !important;
            }
`}
        </style>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
        )}
        </Flex>
        <AnimatePresence> 
        {showAddFile &&<>
            <Box className='documentsContainer' position={'absolute'} bg='white' borderRadius={'xl'} top='17vh' left='14vw' p={'1vw'} width='24vw'   zIndex={98}> 
          <Flex mt='0.6vh'onClick={()=>{document.getElementById('selectImage').click()}} width={'100%'} height={'15vh'} borderColor={'blue.900'} borderWidth={'1px'}  borderRadius={'xl'} flexDirection={'column'}justifyContent={'center'} alignItems={'center'} cursor={'pointer'}> 
            <Icon as={BiUpload} color="color.500" boxSize={8} />
            <Text color={'color.500'} fontWeight={'bold'}>Sube los archivos</Text>
          </Flex>
            <List borderRadius={'xl'}  mt='2vh'borderColor={'blue.900'} borderWidth={'1px'} height={'38vh'} overflowY='scroll'  >
              {selectedFiles.map((file, index) => (
                <ListItem key={index}  px={3}  bg={index%2==0?'gray.200':'gray.100'}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems={'center'} gap={3}> 
                    <Icon size='sm' color='color.500' as={GrDocument} />
                    <Text style={{ wordBreak: 'break-all' }}>{file.name}</Text>
                    </Flex>
                    <IconButton size='sm'colorScheme="red"icon={<RxCross2 />} className='deleteFileButton'aria-label="Eliminar"variant={'red'} color='red'onClick={() => handleRemoveFile(index)}/>
                  </Flex>
                </ListItem>
              ))}
            </List>
      {uploadError&&<Text fontSize='xs'color={'red'}>{uploadError}</Text>}
          <Flex mt='2vh' gap={5} fontSize={'sm'} flexDirection={'row-reverse'}> 
            <Button colorScheme='color' fontSize={'sm'} size='sm' onClick={handleUploadFiles} width={'9vw'}>{waitingUpload?<Spinner/>:'Enviar archivos'}  </Button>
            <Button color='red' borderWidth={'1px'} bg='gray.100' borderColor='gray.300' fontSize={'sm'} size='sm' onClick={()=>{setShowAddFile(false);setSelectedFiles([])}}>Cancelar</Button> 
        </Flex>
        </Box>
       
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}   transition={{ duration: 0.5 }} style={{backdropFilter: 'blur(1px)',WebkitBackdropFilter: 'blur(1px)',position: 'fixed',top: 0,left: 0,width: '100vw',height: '100vh',backgroundColor: 'rgba(0, 0, 0, 0.2)',zIndex: 97}}>
        </motion.div>
        </>}
        </AnimatePresence>
    </>)
}

export default Documents